/* eslint-disable */
import React, { useState } from "react";
import type { FC } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Link,
  IconButton,
  Typography,
  ThemeProvider,
  createMuiTheme,
  CircularProgress,
  Popover,
  Select,
  InputLabel,
  MenuItem,
  Menu,
} from "@material-ui/core";
import { DeviceV2 } from "src/Models/models";
import ClipboardIcon from "../../../icons/Clipboard";
import moment from "moment-timezone";
import { recycleDeviceByTechTag, unrecycleDeviceByTechTag, updateStatusByTechTags } from "src/dataservies/deviceDataservice";
import ReplyIcon from '@mui/icons-material/Reply';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, GridPrintExportMenuItem } from "@mui/x-data-grid";
import { desktopColumns, laptopColumns, mobileColumns, monitorColumns, otherColumns } from "src/logicservices/datagridLogicSevices";
import AddToLocationButton from "./dataGrodToolBar/AddToLocationButton";
import DeleteIcon from '@mui/icons-material/Delete';
import AddToLotButton from "./dataGrodToolBar/AddToLotButton";
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ApprovalIcon from '@mui/icons-material/Approval';
import UpdateStatusButton from "./dataGrodToolBar/UpdateStatusButton";
import ExportOtherPlatFormButton from "./dataGrodToolBar/ExportOtherPlatFormButton";
import ExportWipeReportsButton from "./dataGrodToolBar/ExportWipeReportsButton";
interface DeviceListTableProps {
  devices: DeviceV2[];
  deviceType?: string;
  locationId?: string;
  lotId?: string;
  action?: string;
  onSelectDevices?: (args: string[]) => void;
}

const DeviceListTable: FC<DeviceListTableProps> = (props) => {
  const { devices, onSelectDevices, deviceType, locationId, action, lotId } = props;
  const [loading, setLoading] = useState(false);
  const [Statusloading, setStatusloading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [status, setStatus] = useState("");
  const popopen = Boolean(anchorEl);
  const id = popopen ? "simple-popover" : undefined;
 
  const CustomToolbar = () => {
    return (
      <>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    
       <AddToLocationButton selectedDevices={selectedItems} locationId={locationId}/>
       <UpdateStatusButton selectedDevices={selectedItems} />
       <ExportOtherPlatFormButton selectedDevices={devices.filter(d => selectedItems.includes(d.techTag) )} />
        {action === "addToLot" && (<AddToLotButton selectedDevices={selectedItems} lotId={lotId}/>)} 
        {deviceType === "Recycled" ? (<RemoveFromRecycle />) : (<RenderRecycleButton />)}
        <ExportWipeReportsButton selectedDevices={selectedItems} />
       
  
      
      </GridToolbarContainer>



</>
    );
  };



  const handleRecycleItmes = async () => {
      setLoading(true);
      await recycleDeviceByTechTag(selectedItems);
      window.location.reload();
  }

  const handleUnRecycleItmes = async () => {
    setLoading(true);
      await unrecycleDeviceByTechTag(selectedItems);
      window.location.reload();
  }

  const handleChange = (e) => {
    setStatus(e.target.value);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const RemoveFromRecycle = () => {
    return (
    <Button size="small" startIcon={<ReplyIcon />} disabled={selectedItems.length === 0} onClick={handleUnRecycleItmes}>Remove {selectedItems.length} Devices from Recycle Bin</Button>
)
  }

  const RenderRecycleButton = () => {
    return (
      <Button startIcon={<DeleteIcon />} disabled={selectedItems.length === 0}  size="small" onClick={handleRecycleItmes}>Recycle {selectedItems.length} Devices</Button>
   )
  }

  let columns = [
    {
      field: "techTag",
      minWidth: 120,
      headerName: "Tech Tag",
      renderCell: (data) => {
        return (
          <>
            <Link color="primary" component={RouterLink} to={`/app/computers/${data.row.techTag}`}>
              {data.row.techTag}
            </Link>
            {data.row.hddWipeReport ? (
              <IconButton title="Hard Drive Wipe Report Available" aria-label="delete" size="small">
                <ClipboardIcon fontSize="inherit" />
              </IconButton>
            ) : (
              <></>
            )}
            {data.row.timestamped ? (
              <IconButton title="Hard Drive Wipe Report Stamped" aria-label="delete" size="small">
                <ApprovalIcon fontSize="inherit" />
              </IconButton>
            ) : (
              <></>
            )}
             {data.row.deviceFingerPrintHash ? (
              <IconButton title="Device Finger Print Stamped" aria-label="delete" size="small">
                <FingerprintIcon fontSize="inherit" />
              </IconButton>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      field: "createTimeinUTC",
      headerName: "Created Date",
      minWidth: 180,
      renderCell: (data) => {
        return <Typography variant="inherit">{moment(data.row.createTimeinUTC).tz("America/Los_Angeles").format("YYYY-MM-DD hh:mm:ss A")}</Typography>;
      },
    },
    {
      field: "lotNumber",
      headerName: "Lot #",
      renderCell: (data) => {
        return (
          <Link color="primary" component={RouterLink} to={`/app/lots/${data.row.lotNumber}`}>
            {data.row.lotNumber}
          </Link>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      renderCell: (data) => {
        return (
          <Link color="primary" component={RouterLink} to={`/app/locations/${data.row.location}`}>
            {data.row.location}
          </Link>
        );
      },
    },
    { field: deviceType === "monitor" ? "MonitorInfo.Model" : "SystemInfo.Make", headerName: "Make", minWidth: 112, renderCell: (data) => null },
    { field: deviceType === "monitor" ? "MonitorInfo.MonitorID" :"SystemInfo.Model", headerName: "Model", minWidth: 112, renderCell: (data) => null },
    { field: deviceType === "monitor" ? "MonitorInfo.Serial" :"SystemInfo.Serial", headerName: "Serial Number", minWidth: 112, renderCell: (data) => null },
    { field: "client_id", headerName: "Client Email", minWidth: 222, renderCell: (data) => null },
  ];

  if (deviceType == "Laptop") {
    columns.push(...laptopColumns());
  } else if (deviceType === "Desktop") {
    columns.push(...desktopColumns());
  } else if (deviceType === "monitor") {
    columns.push(...monitorColumns());
  } else if (deviceType === "Mobile") {
    columns.push(...mobileColumns());
  } else if (deviceType === "Other") {
    columns.push(...otherColumns());
  } 

  return (
    <>
      <Card>
        <Popover
          id={id}
          open={popopen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 2 }} width="250px">
            <InputLabel id="status-select">Status</InputLabel>
            <Select fullWidth labelId="status-select" value={status} label="Age" onChange={handleChange}>
              <MenuItem value={"new"}>New</MenuItem>
              <MenuItem value={"Discovered"}>Discovered</MenuItem>
            </Select>
           
            {Statusloading ? <CircularProgress style={{ marginLeft: "7px" }} /> : <></>}
          </Box>
        </Popover>
        <Box>
         
            {/* <Box
          sx={{
            maxWidth: '100%',
          }}
        >
          <IconButton title='magento'>
                        <MagentoIcon fontSize="large" />
                      </IconButton>
        </Box>
        <Box
          sx={{
            maxWidth: '100%',
          }}
        >
        <IconButton title='shopify'>
                        <ShopifyIcon fontSize="large" />
                      </IconButton>
        </Box> */}
         
          <ThemeProvider
            theme={createMuiTheme({
              palette: {
                secondary: {
                  main: "#09820af0",
                },
              },
            })}
          >        
            <div style={{ height: 500 }}>
              <DataGrid onSelectionModelChange={item => setSelectedItems(item)} checkboxSelection columns={columns} rows={devices} getRowId={(row) => row.techTag} components={{ Toolbar: CustomToolbar }} />
              </div>
          </ThemeProvider>
        </Box>
      </Card>
    </>
  );
};

export default DeviceListTable;
