/* eslint-disable */
import { Fragment, useState } from "react";
import type { FC, ChangeEvent } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardActions, CardHeader, Divider, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import { DeviceV2 } from "src/Models/models";

import DesktopDetail from "./Detail/DesktopDetail";
import LaptopDetail from "./Detail/LaptopDetail";
import MobileDetail from "./Detail/MobileDetail";
import MonitorDetail from "./Detail/MonitorDetail";
import OtherDetail from "./Detail/OtherDetail";
import { jsonTocsvAndDownload } from "src/utils/shareFunctions";
import { recycleDeviceByTechTag } from "src/dataservies/deviceDataservice";

interface DeviceDetailsProps {
  device: DeviceV2;
}

const statusOptions = ["Canceled", "Completed", "Rejected"];

const DeviceDetail: FC<DeviceDetailsProps> = (props) => {

  const handleRecycleItmes = async () => {
    await recycleDeviceByTechTag([device.techTag]);
    window.location.reload();
}

  const { device, ...other } = props;

  return (
    <Card {...other}>
       <Grid
        container
        justifyContent="space-between"
        spacing={3}
      >
         <Grid item>
         <CardHeader title="Device Info" />
         </Grid>
         <Grid item>
         <Button
            color="inherit"
            sx={{ m: 2 }}
            variant="contained"
            onClick={()=>{jsonTocsvAndDownload(device)}}
          >
            Export CSV
          </Button>
         </Grid>
      </Grid>
     
      
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Tech Tag
              </Typography>
            </TableCell>
            <TableCell>{device.techTag}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Client
              </Typography>
            </TableCell>
            <TableCell>{device.client_id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Lot #
              </Typography>
            </TableCell>
            <TableCell>{device.lotNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Location
              </Typography>
            </TableCell>
            <TableCell>{device.location}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Value
              </Typography>
            </TableCell>
            <TableCell>{device["value"]}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                SKU
              </Typography>
            </TableCell>
            <TableCell>{device["sku"]}</TableCell>
          </TableRow>
          {device.deviceType == "laptop" && <LaptopDetail device={device} />}
          {device.deviceType == "mobile" && <MobileDetail device={device} />}
          {device.deviceType == "desktop" && <DesktopDetail device={device} />}
          {device.deviceType == "monitor" && <MonitorDetail device={device} />}
          {device.deviceType == "other" && <OtherDetail device={device} />}

          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Status
              </Typography>
            </TableCell>
            <TableCell>{device.status}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid item>
         <Button
            color="secondary"
            sx={{ m: 2 }}
            variant="contained"
            onClick={handleRecycleItmes}
          >
            Recycle Device
          </Button>
         </Grid>
      <CardActions />
    </Card>
  );
};

DeviceDetail.propTypes = {
  // @ts-ignore
  device: PropTypes.object.isRequired,
};

export default DeviceDetail;
