import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Modal, Select, Typography } from "@material-ui/core";
import { FC } from "react";
import { transferDevicesFromLocation } from "src/dataservies/locationDataservice";
import AddIcon from '@mui/icons-material/Add';
import * as React from 'react';
import { getAllLocations } from 'src/dataservies/locationDataservice';
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { Location } from 'src/Models/models';

type addToLocationButtonProps = {
    selectedDevices : String[];
    locationId : string;
  }
  
const  AddToLocationButton :FC<addToLocationButtonProps>  = (props) => {

  const [selectdLocaiton, setSelectedLocation] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [Locations,setLocations] = React.useState<Location[]>([]);
  const isMountedRef = useIsMountedRef();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedLocation(event.target.value.toString());
  };
  const getLocations = React.useCallback(async () => {
    try {
        const result = await getAllLocations();
        if (isMountedRef.current) {
          setLocations(result);
        }
    
    } catch (err) {
     
      console.error(err);
    }
  }, [isMountedRef]);

  
  React.useEffect(() => {
    getLocations();
  }, [getLocations]);

    const addToLocation = async () => {
        await transferDevicesFromLocation(props.selectedDevices,selectdLocaiton);
        location.reload();
      }

    return (<>
    
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Select Location"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Select
          fullWidth
          value={selectdLocaiton}
          onChange={handleChange}
        >
         {Locations.map(location => (
           <MenuItem value={location.id}>{location.name}</MenuItem>
         ))} 
          </Select>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={addToLocation} color="primary" autoFocus>
            Select
          </Button>
        </DialogActions>
      </Dialog>
    
    <Button size='small' startIcon={<AddIcon />} disabled={props.selectedDevices.length === 0} onClick={handleOpen} autoFocus>
    Add {props.selectedDevices.length} devices to location
  </Button></>)
  }

  export default AddToLocationButton;