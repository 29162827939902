/* eslint-disable */
import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Card,
  Link,
  Typography,
  IconButton
} from '@material-ui/core';
import { DeviceV2, Location } from 'src/Models/models';
import moment from 'moment-timezone';
import { deleteLocationById, getLocationById, getMultipleLocationById } from 'src/dataservies/locationDataservice';
import { ExportJsonCsv } from 'react-export-json-csv';
import { useSnackbar } from 'notistack';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, DataGrid } from '@mui/x-data-grid';
import RemoveFromLocationButton from '../devices/dataGrodToolBar/RemoveFromLocationButton';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ApprovalIcon from '@mui/icons-material/Approval';



interface LocationListTableProps {
  locations?: Location[];
  devices? : DeviceV2[];
  locationId: String;
}


const LocationListTable: FC<LocationListTableProps> = (props) => {
  const { locations = [], devices = [] } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const { locationId } = useParams();
  
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        <RemoveFromLocationButton selectedDevices={selectedItems} locationId={locationId}/>
      </GridToolbarContainer>
    );
  };
  

  let columns = [
    {
      field: "techTag",
      headerName: "Tech Tag",
      renderCell: (data) => {
        return (
          <>
            <Link color="primary" component={RouterLink} to={`/app/computers/${data.row.techTag}`}>
              {data.row.techTag}
            </Link>
            {data.row.hddWipeReport === "true" ? (
              <IconButton title="Hard Drive Wipe Report" aria-label="delete" size="small">
               
              </IconButton>
            ) : (
              <></>
            )}
            {data.row.timestamped ? (
              <IconButton title="Hard Drive Wipe Report Stamped" aria-label="delete" size="small">
                <ApprovalIcon fontSize="inherit" />
              </IconButton>
            ) : (
              <></>
            )}
             {data.row.deviceFingerPrintHash ? (
              <IconButton title="Device Finger Print Stamped" aria-label="delete" size="small">
                <FingerprintIcon fontSize="inherit" />
              </IconButton>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      field: "createTimeinUTC",
      headerName: "Created Date",
      minWidth: 180,
      renderCell: (data) => {
        return <Typography variant="inherit">{moment(data.row.createTimeinUTC).tz("America/Los_Angeles").format("YYYY-MM-DD hh:mm:ss A")}</Typography>;
      },
    },
    {
      field: "lotNumber",
      headerName: "Lot #",
      renderCell: (data) => {
        return (
          <Link color="primary" component={RouterLink} to={`/app/lots/${data.row.lotNumber}`}>
            {data.row.lotNumber}
          </Link>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      renderCell: (data) => {
        return (
          <Link color="primary" component={RouterLink} to={`/app/locations/${data.row.location}`}>
            {data.row.location}
          </Link>
        );
      },
    },
    { field: "value", headerName: "Value", hide: false, renderCell: (data) => null },
    { field: "SystemInfo.Make", headerName: "Make", hide: false, renderCell: (data) => null },
    { field: "SystemInfo.Model", headerName: "Model", minWidth: 230,hide: false, renderCell: (data) => null },
    { field: "SystemInfo.Serial", headerName: "Serial", minWidth: 140,hide: false, renderCell: (data) => null },
    { field: "deviceType", headerName: "Device Type", hide: false, renderCell: (data) => null },
    { field: "status", headerName: "Status", hide: false, renderCell: (data) => null },
    { field: "BasicComponent.CpuTitle", headerName: "CPU", minWidth: 300,hide: false, renderCell: (data) => null },
    { field: "BasicComponent.StorageAmount", headerName: "Hard Drive", hide: false, renderCell: (data) => null },
    { field: "BasicComponent.RamTitle", headerName: "RAM", hide: false, renderCell: (data) => null },
  ];



  return (
    <Card>
          <div style={{ height: 500 }}>
              <DataGrid onSelectionModelChange={item => setSelectedItems(item)} checkboxSelection columns={columns} rows={devices} getRowId={(row) => row.techTag} components={{ Toolbar: CustomToolbar }} />
              </div>
    </Card>
  );
};


export default LocationListTable;
