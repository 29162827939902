import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Modal, Select, Typography } from "@material-ui/core";
import { FC, useCallback, useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';
import * as React from 'react';
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { getAllStatus, getTechTagByLocations, getWipeReportByTechtags, updateStatusByTechTags } from "src/dataservies/deviceDataservice";
import { DeviceV2 } from "src/Models/models";
import { arrayToCSV, downloadCSV } from "src/utils/shareFunctions";
import WipePDFMultiHDD from "../WipePDFMultiHDD";
import { PDFDownloadLink } from "@react-pdf/renderer";

type updateStatusProps = {
    selectedDevices : DeviceV2[];
    mode? : String
  }
  
const  ExportWipeReportsButton :FC<updateStatusProps>  = (props) => {

  const [wipeReports, setWipeReports] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const isMountedRef = useIsMountedRef();

  const getWipeReport = useCallback(async () => {
    try {
        const result = await getWipeReportByTechtags(props.selectedDevices);
        if (isMountedRef.current) {
          setWipeReports(result);
        }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  
  useEffect(() => {
    getWipeReport()
  }, [getWipeReport]);



 
  
  

  
    return (<>
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {wipeReports !== null &&  <PDFDownloadLink document={<WipePDFMultiHDD company={wipeReports.owner} device={wipeReports.devices} wipeReport={wipeReports.HddWipeReport} />} fileName="hddWipeReport" style={{ textDecoration: "none" }}>
        <Button color="primary" sx={{ m: 1 }} variant="contained">
          Download PDF
        </Button>
      </PDFDownloadLink>}
         
          </DialogContentText>
        </DialogContent>
        
      </Dialog>
    
    <Button startIcon={<AddIcon />} size='small' disabled={props.selectedDevices.length === 0} onClick={handleOpen} autoFocus>
    Export Wipe Report
  </Button></>)
  }

  export default ExportWipeReportsButton;