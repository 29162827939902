import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
import { HDDReportProps } from 'src/Models/models';
import dataUriToBuffer from 'data-uri-to-buffer';


const COL1_WIDTH = 60;
const COLN_WIDTH = (100 - COL1_WIDTH) / 2;

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    padding: 24
  },
  h4: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.235
  },
  h6: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.6
  },
  subtitle2: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.57
  },
  body2: {
    fontSize: 10,
    lineHeight: 1.43
  },
  gutterBottom: {
    marginBottom: 4
  },
  gutterBottomXL: {
    marginBottom: 15
  },
  gutterTopBottom: {
    marginTop: 30,
    marginBottom: 15
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  brand: {
    width: 150,
    marginBottom: 15
  },
  company: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 32
  },
  references: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 32
  },
  billing: {
    marginTop: 32
  },
  items: {
    marginTop: 32
  },
  notes: {
    marginTop: 32
  },
  table: {
    display: 'flex',
    width: 'auto'
  },
  tableHeader: {},
  tableBody: {},
  tableRow: {
    borderBottomWidth: 1,
    borderColor: '#EEEEEE',
    borderStyle: 'solid',
    flexDirection: 'row'
  },
  tableCell1: {
    padding: 6,
    width: `${COL1_WIDTH}%`
  },
  tableCellN: {
    padding: 6,
    width: `${COLN_WIDTH}%`
  },
  alignRight: {
    textAlign: 'right'
  }
});

const HDDWipeReport: FC<HDDReportProps> = (props) => {
  const { wipeReport, device, companyName } = props;
  const uri = `data:image/jpeg;base64,${device.ownerLogo.profilepic_base64}`;
  let decoded = dataUriToBuffer(uri);
  return (
    <Document>
      <Page
        size="Letter"
        style={styles.page}
      >
        <View style={styles.header}>
          <View>
          <Image style={{ width: "150px", paddingBottom: "15px" }} src={{data : decoded , format: 'png'}} />
            <Text style={styles.h6}>
              DATA ERASURE REPORT
            </Text>
            <Text style={styles.h6}>
              Organization that carried out the wipe: {companyName}
            </Text>
          </View>
        </View>
        <View style={styles.references}>
          <View>
            <Text style={[styles.h6, styles.gutterBottom]}>
              Hardware Details:
            </Text>
            <Text style={styles.body2}>
              Serial Number:
              {' '}
              {device['SystemInfo.Serial']}
            </Text>
            <Text style={styles.body2}>
              Make:
              {' '}
              {device['SystemInfo.Make']}
            </Text>
            <Text style={styles.body2}>
              Model:
              {' '}
              {device['SystemInfo.Model']}
            </Text>
            <Text style={styles.body2}>
              CPU:
              {' '}
              {device['BasicComponent.CpuTitle']}
            </Text>
            <Text style={styles.body2}>
              Memory:
              {' '}
              {device['BasicComponent.RamTitle']}
            </Text>
            <Text style={styles.body2}>
              HDD:
              {' '}
              {device['MiscHardware.storageType']}
            </Text>
          </View>
        </View>
        <View>
          <Text style={[styles.h6, styles.gutterTopBottom]}>
            Data Erasure Log:
          </Text>
          {wipeReport.map((r,index) => (
            <View
              key={r["HddWipeReport.id"]}
              style={styles.gutterBottomXL}
            >
              <Text style={styles.body2}>
              {index+1}.
              </Text>
              <Text style={styles.body2}>
                Name:
                {' '}
                {r["HddWipeReport.Name"]}
              </Text>
              <Text style={styles.body2}>
                Passes:
                {' '}
                {r["HddWipeReport.Passes"]}
              </Text>
              <Text style={styles.body2}>
                Size:
                {' '}
                {r["HddWipeReport.Size"]}
              </Text>
              <Text style={styles.body2}>
                HDD Type:
                {' '}
                {r["HddWipeReport.Type"]}
              </Text>
              <Text style={styles.body2}>
                Conclusion:
                {' '}
                {r["HddWipeReport.Conclusion"]}
              </Text>
              <Text style={styles.body2}>
                Duration:
                {' '}
                {r["HddWipeReport.Duration"]}
              </Text>
              <Text style={styles.body2}>
                Status:
                {' '}
                {r["HddWipeReport.Status"]}
              </Text>
              <Text style={styles.body2}>
                Wipe Performed at:
                {' '}
                {r["HddWipeReport.createTimeinUTC"]}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default HDDWipeReport;
