import { Fragment } from "react";
import { TableCell, TableRow, Typography } from "@material-ui/core";

const MobileDetail = ({ device }) => {
  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            Mobile Type
          </Typography>
        </TableCell>
        <TableCell>{device["SystemInfo.mobileType"]}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            Serial #
          </Typography>
        </TableCell>
        <TableCell>{device["SystemInfo.Serial"]}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            IMEI #
          </Typography>
        </TableCell>
        <TableCell>{device["SystemInfo.IMEI"]}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            Make
          </Typography>
        </TableCell>
        <TableCell>{device["SystemInfo.Make"]}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            Model
          </Typography>
        </TableCell>
        <TableCell>{device["SystemInfo.Model"]}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            Storage Size
          </Typography>
        </TableCell>
        <TableCell>{device["BasicComponent.StorageAmount"]}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            Carrier
          </Typography>
        </TableCell>
        <TableCell>{device["SystemInfo.Carrier"]}</TableCell>
      </TableRow>
    </Fragment>
  );
};

export default MobileDetail;
