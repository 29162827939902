/* eslint-disable */
import { useCallback, useEffect, useState } from "react";
import type { FC, ChangeEvent } from "react";
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, styled, Tab, Tabs } from "@material-ui/core";
import { DeviceV2, WipeReport } from "src/Models/models";
import PropTypes from "prop-types";
import MiscHardwares from "./MiscHardwares";
import UploadDeviceImage from "./UploadDeviceImage";
import React from "react";
import HddWipeReportContent from "./HddWipeReportContent";
import useAuth from "src/hooks/useAuth";
import UploadOther from "./UploadOther";
import GpsPage from "src/pages/dashboard/GpsPage";
import { getGPSLocation } from "src/dataservies/deviceDataservice";
import useIsMountedRef from "src/hooks/useIsMountedRef";


interface DeviceActionsProps {
  onChange: (key, value) => void;
  edit: boolean;
  device: DeviceV2;
  HddWipeReport: WipeReport[];
  deviceType: string;
}


const DeviceActions: FC<DeviceActionsProps> = (props) => {
  const [gps, setGpsLocation] = useState<any>([]);
  const isMountedRef = useIsMountedRef();
  const { device, HddWipeReport, edit, onChange, deviceType } = props;
  const [currentTab, setCurrentTab] = useState<string>("misc");
  const {user} = useAuth();
  const [filters, setFilters] = useState<any>({
    hasAcceptedMarketing: null,
    isProspect: null,
    isReturning: null,
  });
  

  const getDevice = useCallback(async () => {
    try {
      const gpsLocation = await getGPSLocation(device.techTag);
      if (isMountedRef.current) {
        setGpsLocation(gpsLocation);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);


  useEffect(() => {
    getDevice()
  }, [getDevice]);



  const tabs = () => {
    let tabsContent = [
      {
        label: "Misc Hardware Info",
        value: "misc",
      },
      {
        label: "HDD Wipe Report",
        value: "hddWipe",
      },
      {
        label: "Device Images",
        value: "photo",
      },
      {
        label: "Other",
        value: "other",
      },
      {
        label: "GPS Location",
        value: "gps",
      },
    ];
    if(deviceType === 'monitor'){
      tabsContent= tabsContent.filter(t => t.value !== "hddWipe")
    }
    return tabsContent
  } 

  

  const handleChange = (key, value) => {
    onChange(key, value);
  };


  
  

  
  
  
  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    const updatedFilters = {
      ...filters,
      hasAcceptedMarketing: null,
      isProspect: null,
      isReturning: null,
    };

    if (value !== "all") {
      updatedFilters[value] = true;
    }

    setFilters(updatedFilters);
    setCurrentTab(value);
  };

  return (
    <Card>
      <Tabs indicatorColor="primary" onChange={handleTabsChange} scrollButtons="auto" textColor="primary" value={currentTab} variant="scrollable">
        {tabs().map((tab) => (
          <Tab style={{ textTransform: "none" }} key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider />
      {currentTab === "photo" && <UploadDeviceImage edit={edit} device={device} />}
      {currentTab === "other" && <UploadOther edit={edit} device={device} />}
      {currentTab === "misc" && <MiscHardwares onChange={handleChange} device={device} edit={edit} deviceType={deviceType} />}
      {currentTab === "hddWipe" && <HddWipeReportContent companyName={user.name} deviceType={deviceType} HddWipeReport={HddWipeReport} device={device} edit={edit} onChange={handleChange} />}
      {currentTab === "gps" && <GpsPage publicMode={false} gps={gps} qrhash={device.qrHash}/>}
    </Card>
  );
};

DeviceActions.propTypes = {
  // @ts-ignore
  device: PropTypes.object.isRequired,
  // @ts-ignore
  HddWipeReport: PropTypes.array.isRequired,
  edit: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};


export default DeviceActions;
