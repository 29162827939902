/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import type { FC, KeyboardEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useFirestore } from 'reactfire';
import { Device } from 'src/Models/models';
import SearchIcon from '../../icons/Search';
import XIcon from '../../icons/X';
import Scrollbar from '../Scrollbar';
import wait from '../../utils/wait';
import { getDeviceByTechtag } from 'src/dataservies/deviceDataservice';

const ContentSearch: FC = () => {
  const [value, setValue] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [deviceSearchResult, setDevicesSearchResult] = useState<Device>(null);
  const [emptyResult, setEmptyResult] = useState<boolean>(false);
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const searchDevice = async (assetTag: string) : Promise<Device> => {
    setEmptyResult(false);
   let result = await getDeviceByTechtag(assetTag);
 
    if(!result){
      setEmptyResult(true);
    }
    return result;
  };

  const search = async (): Promise<void> => {
    setShowResults(false);
    setIsLoading(true);
    // Do search here
    setDevicesSearchResult(await searchDevice(value));
    setIsLoading(false);
    setShowResults(true);
  };
  const deviceTypeURL = (dt): string => {
    switch (dt){
      case null :
        return 'unassigned';
      
      default:
        return dt;
      }
    }
  
  const handleClick = (): void => {
    search();
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === 'Enter' || event.code === "NumpadEnter") {
      search();
    }
  };

  return (
    <>
      <Tooltip title="Search">
        <IconButton
          color="inherit"
          onClick={handleOpen}
        >
          <SearchIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="top"
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: '100%' }
        }}
        variant="temporary"
      >
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton onClick={handleClose}>
              <XIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ p: 3 }}>
          <Container maxWidth="md">
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  )
                }}
                onFocus={(e):void => e.target.select()}
                onChange={(event): void => setValue(event.target.value)}
                onKeyUp={handleKeyUp}
                placeholder="Enter TechTag here"
                value={value}
              />
              <Button
                color="primary"
                onClick={handleClick}
                size="large"
                sx={{ ml: 2 }}
                variant="contained"
              >
                Search
              </Button>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Scrollbar options={{ suppressScrollX: true }}>
                {
                  isLoading
                    ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    )
                    : (
                      <>
                        {showResults && deviceSearchResult ? (
                          <>
                           
                              <Box
                                sx={{ mb: 2 }}
                              >
                                <Link
                                  color="textPrimary"
                                  component={RouterLink}
                                  to={`/app/${deviceTypeURL(deviceSearchResult.deviceType)}/${deviceSearchResult.techTag}`}
                                  variant="h5"
                                >
                                  Tech Tag:
                                  {' '}
                                  {deviceSearchResult.techTag}
                                </Link>
                                <Typography
                                  color="textPrimary"
                                  variant="body2"
                                  sx={{pt : 1}}
                                >
                                  Device type:
                                  {' '}
                                  {deviceSearchResult.deviceType}
                                </Typography>
                                <Typography
                                  color="textPrimary"
                                  variant="body2"
                                >
                                  Serial Number:
                                  {' '}
                                  {deviceSearchResult['SystemInfo.Serial']}
                                </Typography>
                                <Typography
                                  color="textPrimary"
                                  variant="body2"
                                >
                                  Make & Model:
                                  {' '}
                                  {deviceSearchResult['SystemInfo.Make']}
                                  {' - '}
                                  {' '}
                                  {deviceSearchResult['SystemInfo.Model']}
                                </Typography>
                              </Box>
                            
                          </>
                        ) : emptyResult ? (<h2>No Result</h2>) : (<></>)}
                      </>
                    )
                }
              </Scrollbar>
            </Box>
          </Container>
        </Box>
      </Drawer>
    </>
  );
};

export default ContentSearch;
