/* eslint-disable */
import type { FC } from "react";
import { Fragment } from "react";
import { Box, CardHeader, Grid, TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DeviceV2 } from "src/Models/models";
import shortid from "shortid";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { miscHardwareName, miscHardwareNameTextField } from "src/utils/shareFunctions";

import MiscLaptop from "./Edit/MiscLaptop";
import MiscMobile from "./Edit/MiscMobile";
import MiscDesktop from "./Edit/MiscDesktop";
import MiscMonitor from "./Edit/MiscMonitor";
import MiscOther from "./Edit/MiscOther";

interface MiscHardware {
  edit: boolean;
  device: DeviceV2;
  onChange: (key, value) => void;
  deviceType: string;
  passport?: boolean;
}

const MiscHardwareTab: FC<MiscHardware> = (props) => {
   const { device, edit, onChange, deviceType, passport} = props;
  const handleChange = (event, checked) => {
    if (!edit) {
      return;
    }
    onChange(event.target.name, checked ? 1 : 0);
  };
  const handleTextChange = (event) => {
    if (!edit) {
      return;
    }
    onChange(event.target.name, event.target.value);
  };
  return (
    <Fragment>
      {deviceType == "laptop" && <MiscLaptop passport={passport} device={device} handleChange={handleChange} edit={edit} handleTextChange={handleTextChange} />}
      {deviceType == "mobile" && <MiscMobile passport={passport} device={device} handleChange={handleChange} edit={edit} handleTextChange={handleTextChange} />}
      {deviceType == "desktop" && <MiscDesktop passport={passport} device={device} handleChange={handleChange} edit={edit} handleTextChange={handleTextChange} />}
      {deviceType == "monitor" && <MiscMonitor passport={passport} device={device} handleChange={handleChange} edit={edit} handleTextChange={handleTextChange} />}
      {deviceType == "other" && <MiscOther passport={passport} device={device} handleChange={handleChange} edit={edit} handleTextChange={handleTextChange} />}

      {deviceType == "computer" && (
        <Formik
          initialValues={{
            backLitKeyboard: device["MiscHardware.backLitKeyboard"] || false,
            bluetooth: device["MiscHardware.bluetooth"] || false,
            camera3d: device["MiscHardware.camera3d"] || false,
            color: device["MiscHardware.color"] || false,
            displayPort: device["MiscHardware.displayPort"] || false,
            ethernet: device["MiscHardware.ethernet"] || false,
            hdmi: device["MiscHardware.hdmi"] || false,
            hsdpa: device["MiscHardware.hsdpa"] || false,
            lanCard: device["MiscHardware.lanCard"] || false,
            microHDMI: device["MiscHardware.microHDMI"] || false,
            microUSB: device["MiscHardware.microUSB"] || false,
            microphone: device["MiscHardware.microphone"] || false,
            miniEthernet: device["MiscHardware.miniEthernet"] || false,
            miniUSB: device["MiscHardware.miniUSB"] || false,
            minidisplayport: device["MiscHardware.minidisplayport"] || false,
            rugged: device["MiscHardware.rugged"] || false,
            screensize: device["MiscHardware.screensize"] || false,
            storageType: device["MiscHardware.storageType"] || false,
            tabletPC: device["MiscHardware.tabletPC"] || false,
            touchScreen: device["MiscHardware.touchScreen"] || false,
            usb1: device["MiscHardware.usb1"] || false,
            usb2: device["MiscHardware.usb2"] || false,
            webcam: device["MiscHardware.webcam"] || false,
          }}
          onSubmit={async (): Promise<void> => {}}
        >
          {(): JSX.Element => (
            <form>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexWrap: "wrap",
                  m: -1,
                  p: 2,
                }}
              >
                <Grid container justifyContent="space-between" spacing={3}>
                  <Grid item>
                    <CardHeader title="Device Info" />
                  </Grid>
                </Grid>
                <Grid style={{ marginLeft: "5px" }} container spacing={1}>
                  {Object.keys(device)
                    .sort()
                    .filter((key) => key.includes("MiscHardware") && !key.includes("techTag"))
                    .map((key) =>
                      miscHardwareName(key) === "" ? (
                        <Grid key={shortid.generate()} item xs={3}>
                          <TextField name={key} disabled={!edit} value={device[key] ?? ""} onBlur={handleTextChange} label={miscHardwareNameTextField(key)} size="small" />
                        </Grid>
                      ) : (
                        <Grid key={shortid.generate()} item xs={3}>
                          <FormControlLabel control={<Checkbox color="primary" checked={device[key]} onChange={handleChange} name={key} />} label={miscHardwareName(key)} />
                        </Grid>
                      )
                    )}
                </Grid>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </Fragment>
  );
};

MiscHardwareTab.propTypes = {
  // @ts-ignore
  device: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  edit: PropTypes.bool.isRequired,
};

export default MiscHardwareTab;
