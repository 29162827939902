import { Box, Button, Input, Dialog, styled, DialogActions, DialogContent, DialogContentText, DialogTitle, CardHeader } from "@material-ui/core";
import { pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import axios from "axios";
import firebase from "firebase";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { useStorageDownloadURL } from "reactfire";
import { DeviceV2, WipeReport } from "src/Models/models";
import { getBase64 } from "src/utils/shareFunctions";
import HddWipeTab from "./HddWipeTab";
import WipePDF from "./WipePDF";
import { sha256 } from 'js-sha256';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { FetchFilePathBtn } from "src/pages/dashboard/DevicesDetail";

interface HddWipeReportProps {
    onChange: (key, value) => void;
    edit: boolean;
    device: DeviceV2;
    HddWipeReport: WipeReport[];
    deviceType: string;
    companyName: string;
  }

const HddWipeReportContent: FC<HddWipeReportProps> = (props) => { 
    const Input = styled('input')({
        display: 'none',
      });
      const { enqueueSnackbar } = useSnackbar();
    const { device, HddWipeReport, edit, companyName } = props;
    const [viewPDF, setViewPDF] = useState<boolean>(false);
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [wipeReports, setWipeReports] = useState([]);
    const [selectedFile, setSelectedFile] = useState (null);

    const handleOnFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setOpen(true);
      }
      const FetchFilePath = ({ storagePath }) => {

        const { data: imageURL } = useStorageDownloadURL(firebase.storage().ref(storagePath));
        return <a href={imageURL} target='_blank'>{storagePath}</a>;
      };
      const handleClose = () => {
        setOpen(false);
      };
      const handleUpload = () => {
        getBase64(selectedFile, async (base64) => {
          await axios.post('https://us-central1-techreusetoolkit.cloudfunctions.net/publicApi/public/uploadWipeReport', {
            techTag: device.techTag,
            fileName: selectedFile.name,
            baseString: base64,
            fileType: selectedFile.type
          });
          enqueueSnackbar('Image Uploaded', {
            autoHideDuration: 3000,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top'
            },
            variant: 'success'
          });
          setOpen(false);
        });
      }
      

      const getPdfBlob = async () =>   {
        setLoadingStatus(true);
        let blobPdf = await pdf(WipePDF({companyName,device,wipeReport : HddWipeReport})).toBlob();
        const arrayBuffer = await blobPdf.arrayBuffer();
        var reader = new FileReader();
        reader.readAsDataURL(blobPdf); 
        reader.onloadend = async() => {
          var base64data = reader.result;          
       
          try{
            await axios.post('https://us-central1-techreusetoolkit.cloudfunctions.net/publicApi/public/uploadWipeReportOriginStamp', {
              techTag: device.techTag,
              fileName: `wipeReport_${device.techTag}_originstamp.pdf`,
              baseString: base64data.toString().split(',')[1],
              fileType: "application/pdf",
              hash : sha256(arrayBuffer),
              
            });
            setLoadingStatus(false);
            enqueueSnackbar('Report Stamped and Uploaded', {
              autoHideDuration: 3000,
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top'
              },
              variant: 'success'
            });
          }
          catch{
            enqueueSnackbar('Something went wrong', {
              autoHideDuration: 3000,
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top'
              },
              variant: 'warning'
            });
            setLoadingStatus(false);
          }

        }
    }
return (
<>

<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Upload Wipe Report"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirm Upload {selectedFile?.name ?? ""} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpload} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {HddWipeReport.length > 0 && HddWipeReport[0]["HddWipeReport.techTag"] ? (<Box sx={{ m: 1 }}>
      <Button color="primary" onClick={(): void => setViewPDF(true)} sx={{ m: 1 }} variant="outlined">
        Preview PDF
      </Button>
      <PDFDownloadLink document={<WipePDF companyName={companyName} device={device} wipeReport={HddWipeReport} />} fileName="hddWipeReport" style={{ textDecoration: "none" }}>
        <Button color="primary" sx={{ m: 1 }} variant="contained">
          Download PDF
        </Button>
      </PDFDownloadLink>
      {edit && <LoadingButton pending={loadingStatus} ><Button color="primary" disabled={HddWipeReport.length > 0 && HddWipeReport[0]["HddWipeReport.techTag"] === null} onClick={getPdfBlob} sx={{ m: 1 }} variant="contained">
        TimeStamp
      </Button></LoadingButton> }
      <label htmlFor="contained-button-file">
    <Input onChange={handleOnFileChange} accept="image/*" id="contained-button-file" type="file" />
    {edit && device.deviceType === "mobile" && (<Button variant="contained" component="span">
      Upload Wipe Report
    </Button>)}
  </label>
  {device.timestamped && <FetchFilePathBtn btnName="Download Time Stamped File" storagePath={`devices/${device.techTag}/wipeReport/wipeReport_${device.techTag}_originstamp.pdf`} />}
    {device.deviceType === "mobile" && (<div>
      <p>Uploaded Files</p>
    {wipeReports.map((file,index) => {
            return (
              <p key={index}><FetchFilePath storagePath={file} ></FetchFilePath></p>
            )
          })}
</div>)}
     
      <HddWipeTab companyName={companyName} device={device} wipeReport={HddWipeReport} />

      
      <Dialog fullScreen open={viewPDF}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              backgroundColor: "background.default",
              p: 2,
            }}
          >
            <Button color="primary" onClick={(): void => setViewPDF(false)} variant="contained">
              Back
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <PDFViewer height="100%" style={{ border: "none" }} width="100%">
              <WipePDF companyName={companyName} device={device} wipeReport={HddWipeReport} />
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>
    </Box>) : <Box sx={{ m: 1 }}><CardHeader title="No HDD Wipe Report Found" /></Box> }
</>
   
  )

}


export default HddWipeReportContent;