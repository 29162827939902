import { useEffect } from "react";
import type { FC } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Avatar, Box, Button, Divider, Drawer, Hidden, Typography } from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/Receipt";
import LabelIcon from "@material-ui/icons/Label";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhonelinkIcon from "@material-ui/icons/Phonelink";
import useAuth from "../../hooks/useAuth";
import { FaRecycle } from "react-icons/fa";
import HomeIcon from "../../icons/Home";
import UsersIcon from "../../icons/Users";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import ExclamationCircleIcon from "../../icons/ExclamationCircle";
import DownloadIcon from "../../icons/Download";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = [
  {
    title: "",
    items: [
      {
        title: "Home",
        path: "/app",
        icon: <HomeIcon fontSize="small" />,
      },
      {
        title: "Tags",
        path: "/app/tags",
        icon: <LabelIcon fontSize="small" />,
      },
      {
        title: "Devices",
        icon: <PhonelinkIcon fontSize="small" />,
        children: [
          {
            title: "Desktop",
            path: "/app/desktop",
          },
          {
            title: "Laptop",
            path: "/app/laptop",
          },
          {
            title: "Monitors",
            path: "/app/monitors",
          },
          {
            title: "Mobile",
            path: "/app/mobile",
          },
          {
            title: "Other",
            path: "/app/other",
          },
          {
            title: "Unassigned",
            path: "/app/unassigned",
          },
        ],
      },
      {
        title: "Locations",
        path: "/app/locations",
        icon: <LocationOnIcon fontSize="small" />,
        children: [
          {
            title: "List",
            path: "/app/locations",
          },
          {
            title: "Create",
            path: "/app/locations/new",
          },
        ],
      },
      {
        title: "Clients",
        path: "/app/customer",
        icon: <UsersIcon fontSize="small" />,
      },

      {
        title: "Lots",
        path: "/app/lots",
        icon: <ReceiptIcon fontSize="small" />,
        children: [
          {
            title: "List",
            path: "/app/lots",
          },
          {
            title: "Create",
            path: "/app/lots/new",
          },
        ],
      },
      {
        title: "Recycle Bin",
        path: "/app/recycleBin",
        icon: <FaRecycle />,
      },
      {
        title: "Help",
        path: "/app/help",
        icon: <ExclamationCircleIcon fontSize="small" />,
      },
      {
        title: "Download",
        path: "/app/download",
        icon: <DownloadIcon fontSize="small" />,
      },
    ],
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);


  useEffect(() => {
      if(user?.role === "admin" && !sections[0].items.find(t => t.title === "Admin Only")){
        sections[0].items.push({
          title: "Admin Only",
          path: "/app/admin",
          icon: <AdminPanelSettingsIcon fontSize="small" />,
        });
      }
    

  }, []);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          <img
            style={{ width: "230px", paddingBottom: "15px" }}
            alt="logo"
            src="https://firebasestorage.googleapis.com/v0/b/tech-reuse.appspot.com/o/TechSpec-logo%20(1).png?alt=media&token=e572dddf-17c4-4383-aa62-43aef977b0ba"
          />
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <RouterLink to="/app/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: "pointer",
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.email}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width: 280,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              top: "64px !Important",
              width: 280,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
