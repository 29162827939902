import React, { Fragment } from "react";
import { Card, Box, TextField, CardHeader, Grid, Checkbox } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Formik } from "formik";
import shortid from "shortid";

const MiscMobile = ({ device, handleChange, edit, handleTextChange, passport }) => {
  return (
    <Formik initialValues={{}} onSubmit={async (): Promise<void> => {}}>
      {(): JSX.Element => (
        <form>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              m: -1,
              p: 2,
            }}
          >
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <CardHeader title="Device Info" />
              </Grid>
            </Grid>
            <Grid style={{ marginLeft: "5px" }} container spacing={1}>
              <Grid item xs={3}>
                <TextField name="grade" value={device["grade"] ?? ""} disabled={!edit} onChange={handleTextChange} label="Grade" size="small" />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="MiscHardware.screenSize"
                  value={device["MiscHardware.screenSize"] ?? ""}
                  disabled={!edit}
                  onChange={handleTextChange}
                  label="Screen Size"
                  size="small"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField name="MiscHardware.color" value={device["MiscHardware.color"] ?? ""} disabled={!edit} onChange={handleTextChange} label="Color" size="small" />
              </Grid>

              <Grid key={shortid.generate()} item xs={3}>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={device["MiscHardware.activationLock"]} onChange={handleChange} name="MiscHardware.activationLock" />}
                  label="Activation Lock"
                />
              </Grid>

              <Grid key={shortid.generate()} item xs={3}>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={device["MiscHardware.managementLocked"]} onChange={handleChange} name="MiscHardware.managementLocked" />}
                  label="Management Locked"
                />
              </Grid>

              <Grid key={shortid.generate()} item xs={3}>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={device["MiscHardware.carrierLock"]} onChange={handleChange} name="MiscHardware.carrierLock" />}
                  label="Carrier Locked"
                />
              </Grid>
            </Grid>
            <Grid xs={12} style={{ marginTop: "20px" }}>
              {passport ? null : (<TextField
                name="description"
                value={device["description"] ?? ""}
                disabled={!edit}
                onChange={handleTextChange}
                label="Description"
                size="medium"
                minRows="2"
                maxRows="4"
                multiline
                fullWidth
              />)}
            </Grid>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default MiscMobile;
