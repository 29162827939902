import axios from "axios";
import firebase from "./firebase";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async (request) => {
  if (!request.url.includes("https://us-central1-techreusetoolkit.cloudfunctions.net/")) {
    return request;
  }
  if (!window.location.href.includes("/auth/") && !window.location.href.includes("/public/")) {
    const idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true);
    const { token } = idTokenResult;
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || "Something went wrong")
);

export default axiosInstance;
