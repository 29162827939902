import { Button } from "@material-ui/core";
import { FC, useState } from "react";
import { DeviceV2, Location } from "src/Models/models";
import AddDevicesToLotDialog from "./location/share/AddDevicesToLotDialog";
import ReleaseDevicesToLocationDialog from "./location/share/ReleaseDevicesToLocationDialog";

interface ScanMultipleDevicesDialogProps {
    UnAssingedDevices : DeviceV2[];
    importType : string;
    lotId? : string;
    locationId? : string;
    locationDevices? : DeviceV2[];
}

const ScanMultipleDevicesDialog : FC<ScanMultipleDevicesDialogProps> = (props) => {
    const {UnAssingedDevices = [],lotId,importType,locationId,locationDevices = []} = props; 
    const [ImportDialogopen, setImportDialogopen]  = useState(false);
    const [ReleaseDialogopen, setReleaseDialogopen]  = useState(false);
    const openImportDialog = () => {
        setImportDialogopen(true);
      }
      const HandleImportDialog = () => {
        setImportDialogopen(false);
      }
      const openReleaseDialog = () => {
        setReleaseDialogopen(true);
      }
      const HandleReleaseDialog = () => {
        setReleaseDialogopen(false);
      }
    return (
        <>
              <ReleaseDevicesToLocationDialog  validationSource={locationDevices.map(u => u.techTag)} locationId={Number(locationId)} open={ReleaseDialogopen} handleClose={HandleReleaseDialog} />

              <AddDevicesToLotDialog importType={importType} validationSource={UnAssingedDevices.map(u => u.techTag)} lotID={Number(lotId)} locationId={Number(locationId)} open={ImportDialogopen} handleClose={HandleImportDialog} />
         {importType === "location" && <Button
                  color="primary"
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => openReleaseDialog()}
                >
                  Release Devices from this {importType}
                </Button>}
                <Button
                  color="primary"
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => openImportDialog()}
                >
                  Scan Devices to this {importType}
                </Button>
        </>
    )
}

export default ScanMultipleDevicesDialog;