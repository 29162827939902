import { Button } from "@material-ui/core";
import { FC } from "react";
import { transferDevicesFromLocation } from "src/dataservies/locationDataservice";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';


type RemoveFromLocationButtonProps = {
    selectedDevices : String[];
    locationId : string;
  }
  
const  RemoveFromLocationButton :FC<RemoveFromLocationButtonProps>  = (props) => {

    const addToLocation = async () => {
        await transferDevicesFromLocation(props.selectedDevices,null);
        location.reload();
      }

    return (<Button size='small' startIcon={<HorizontalRuleIcon />} disabled={props.selectedDevices.length === 0} onClick={addToLocation} autoFocus>
    Remove {props.selectedDevices.length} devices from location {props.locationId}
  </Button>)
  }

  export default RemoveFromLocationButton;