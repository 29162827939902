export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: 'AIzaSyCiXk4ZDfMfXxKOxtgbYTTp7sXPdbj7W1c',
  authDomain: 'techreusetoolkit.firebaseapp.com',
  projectId: 'techreusetoolkit',
  storageBucket: 'techreusetoolkit.appspot.com',
  messagingSenderId: '39140156402',
  appId: '1:39140156402:web:33d70880334734bd8dc16b'
};

export const gtmConfig = {
  containerId: 'GTM-5T4FLM9'
};
