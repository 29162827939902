import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = experimentalStyled('svg')();

const Logo: FC<LogoProps> = (props) => (
  <LogoRoot
    height="52"
    version="1.1"
    viewBox="0 0 52 52"
    width="52"
    {...props}
  >
    <title>MKP</title>
    <defs>
      <filter id="filter">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.329412 0 0 0 0 0.866667 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g
      transform="translate(0.000000,396.000000) scale(0.100000,-0.100000)"
      fill="#6aa84f"
      stroke="none"
    >
      <path d="M1421 3758 c-44 -55 -61 -85 -55 -93 42 -58 120 -153 124 -152 3 1
40 38 82 82 l77 80 -81 83 c-44 45 -81 82 -82 81 0 0 -30 -37 -65 -81z m132
-16 c31 -33 57 -63 57 -68 0 -5 -27 -36 -59 -68 -50 -50 -62 -57 -73 -45 -27
29 -88 108 -88 114 0 6 27 41 85 112 6 7 12 12 15 13 3 0 31 -26 63 -58z"
      />
      <path d="M1171 3471 l-62 -70 57 -78 c31 -43 58 -80 60 -82 4 -4 163 121 163
129 1 7 -137 164 -148 168 -4 1 -36 -29 -70 -67z m171 -111 c-34 -31 -100 -80
-107 -80 -9 0 -21 15 -66 78 l-30 42 48 56 47 56 63 -68 c58 -63 61 -69 45
-84z" />
      <path d="M1671 3469 l-64 -69 59 -77 c33 -42 62 -81 66 -87 4 -6 40 25 85 74
l79 84 -74 70 c-41 39 -77 72 -81 73 -3 1 -35 -30 -70 -68z m133 -140 l-61
-61 -20 21 c-11 12 -34 41 -51 65 l-32 44 52 56 52 57 60 -61 61 -60 -61 -61z"
      />
      <path d="M1430 3179 l-52 -89 93 -113 c51 -61 172 -202 268 -311 97 -110 222
-253 279 -318 l103 -119 -18 -23 c-10 -13 -75 -88 -144 -167 -70 -79 -137
-157 -151 -172 -22 -27 -27 -28 -70 -22 -44 7 -49 11 -139 126 -52 65 -97 119
-100 119 -8 0 -129 -141 -129 -151 0 -6 251 -301 407 -479 46 -52 83 -97 83
-101 0 -7 -182 -217 -302 -349 l-67 -73 -28 34 c-15 18 -62 72 -103 119 -41
47 -110 127 -153 177 l-77 92 115 143 c63 78 115 145 115 149 0 4 -49 66 -109
138 -60 72 -112 135 -115 140 -3 5 23 43 58 85 36 41 86 105 112 141 42 60 46
69 34 90 -31 57 -147 200 -251 307 l-114 117 -61 -78 -61 -78 127 -143 128
-143 -101 -116 c-56 -64 -127 -146 -157 -183 -46 -57 -63 -70 -105 -82 -27 -8
-50 -14 -50 -13 0 1 -41 52 -89 112 l-88 111 -77 -24 c-42 -13 -81 -25 -88
-27 -15 -5 37 -100 114 -207 73 -102 270 -307 280 -292 4 6 57 68 116 137
l108 127 69 -53 c38 -28 69 -54 69 -57 0 -3 -57 -70 -128 -150 l-128 -145 73
-85 c40 -47 157 -182 259 -300 l185 -215 2 -323 3 -323 123 -2 122 -2 0 328 0
328 390 442 390 442 -69 66 -70 67 -133 -134 c-73 -74 -135 -133 -139 -132 -4
2 -27 33 -52 70 l-44 67 371 422 c204 232 372 425 374 430 2 4 -23 42 -54 84
l-58 77 -51 -53 c-27 -30 -88 -95 -135 -146 l-85 -92 -117 132 c-147 166 -231
274 -225 290 3 7 30 48 62 91 97 135 136 197 133 206 -6 19 -103 149 -109 147
-4 -1 -62 -68 -129 -149 l-122 -147 -51 62 c-28 34 -88 106 -133 159 l-82 97
-52 -90z m93 3 c9 -15 36 -48 59 -74 24 -25 67 -73 97 -107 29 -34 61 -61 72
-61 10 0 40 27 70 63 28 34 56 67 63 72 6 6 33 38 60 73 54 71 56 71 112 -9
l28 -40 -19 -32 c-16 -28 -118 -171 -144 -203 -21 -26 -32 -58 -27 -79 11 -44
273 -356 343 -409 l26 -20 89 95 c49 52 108 114 130 138 41 43 42 43 57 23 9
-11 27 -35 41 -53 14 -17 26 -35 28 -39 1 -4 -41 -56 -93 -116 -124 -141 -176
-199 -320 -364 -65 -74 -144 -164 -176 -200 -31 -36 -78 -91 -104 -122 l-47
-58 42 -69 c46 -78 62 -94 84 -89 9 2 72 59 141 127 122 122 126 124 143 105
10 -10 33 -30 50 -44 18 -14 32 -29 32 -35 0 -5 -51 -67 -113 -136 -61 -70
-134 -153 -162 -185 -27 -32 -57 -65 -65 -74 -8 -9 -78 -88 -155 -175 -77 -88
-156 -178 -176 -200 -94 -103 -89 -89 -89 -227 0 -69 -3 -213 -7 -321 l-6
-197 -94 0 -93 0 0 313 0 313 -37 45 c-21 24 -40 46 -43 49 -3 3 -30 34 -60
70 -30 36 -57 67 -60 70 -3 3 -27 32 -55 65 -27 33 -71 83 -96 110 -26 28 -58
64 -70 80 -13 17 -38 46 -56 65 -18 19 -33 38 -33 42 0 4 54 69 120 145 66 75
120 143 120 151 0 16 -30 47 -82 83 -18 12 -36 29 -41 35 -10 17 -59 8 -75
-14 -12 -16 -67 -82 -105 -126 -11 -11 -33 -38 -50 -58 -17 -21 -34 -38 -38
-38 -10 0 -107 97 -170 170 -99 114 -179 233 -179 265 0 13 83 45 115 45 7 0
32 -26 56 -57 24 -31 65 -79 91 -107 l46 -50 55 17 c50 16 63 26 123 100 37
45 70 84 73 87 3 3 33 37 65 75 32 39 69 79 81 91 50 46 46 55 -86 199 -25 27
-55 61 -65 74 -10 13 -27 31 -36 40 -10 8 -18 19 -18 22 0 13 80 109 91 109
24 0 244 -244 313 -348 36 -52 36 -51 -84 -199 -47 -58 -89 -111 -95 -119 -9
-13 15 -69 35 -84 19 -13 180 -214 178 -222 -4 -10 -67 -91 -150 -192 -37 -44
-68 -88 -68 -96 0 -8 63 -89 141 -180 161 -188 228 -260 245 -260 6 0 49 42
95 93 46 50 88 97 94 103 11 12 87 99 154 177 22 27 41 56 41 65 0 18 -40 72
-107 143 -23 24 -56 63 -75 86 -33 41 -195 230 -231 268 -11 11 -30 34 -44 52
l-25 32 38 46 c58 71 64 72 110 14 136 -174 131 -169 194 -176 l55 -5 105 118
c223 254 240 274 240 288 0 12 -33 61 -50 76 -3 3 -33 37 -65 75 -32 39 -88
104 -125 145 -37 41 -80 90 -96 109 -16 19 -34 39 -40 45 -29 31 -161 185
-252 294 -113 136 -108 117 -55 209 26 46 40 47 66 5z"
      />
      <path d="M939 3198 c-25 -29 -56 -68 -69 -86 l-24 -32 74 -75 c41 -41 77 -75
80 -75 3 0 32 41 64 90 l59 91 -24 27 c-49 56 -103 112 -109 112 -3 0 -26 -24
-51 -52z m106 -33 l47 -55 -44 -70 c-24 -38 -47 -69 -51 -70 -5 0 -34 24 -64
54 -51 49 -54 55 -43 77 11 20 98 119 105 119 1 0 24 -25 50 -55z"
      />
      <path d="M2195 2896 l-77 -85 60 -75 c34 -41 67 -84 74 -95 7 -12 15 -21 19
-21 12 0 99 170 99 194 0 18 -60 122 -95 164 -1 1 -37 -36 -80 -82z m115 -11
c16 -24 30 -55 30 -70 0 -25 -34 -101 -63 -139 -14 -19 -15 -19 -25 0 -6 11
-29 42 -52 70 -22 27 -39 56 -38 65 4 23 99 130 109 123 5 -3 22 -25 39 -49z"
      />
      <path d="M666 2900 c-37 -47 -46 -65 -41 -83 13 -42 78 -187 84 -187 4 0 47
40 96 90 l89 90 -44 37 c-24 21 -65 55 -91 75 l-46 38 -47 -60z m126 -36 l70
-57 -68 -68 c-38 -38 -72 -69 -75 -69 -3 0 -13 19 -23 43 -10 23 -26 58 -36
77 l-19 36 32 47 c18 25 36 47 41 47 5 0 40 -25 78 -56z"
      />
      <path d="M1166 2908 c-42 -65 -42 -84 4 -174 24 -47 47 -83 51 -82 12 4 187
152 188 158 1 13 -159 111 -191 116 -31 5 -38 3 -52 -18z m124 -40 c29 -18 58
-40 63 -49 10 -16 -6 -34 -99 -113 l-32 -26 -31 63 c-35 70 -38 102 -15 135
21 30 51 28 114 -10z"
      />
      <path d="M408 2604 l-66 -67 55 -86 c31 -47 60 -90 65 -96 6 -6 37 20 89 75
l79 84 -77 78 -78 78 -67 -66z m182 -94 c0 -9 -119 -124 -123 -119 -23 26 -87
131 -87 141 0 7 21 34 47 60 l48 49 57 -63 c32 -34 58 -65 58 -68z"
      />
      <path d="M1427 2606 l-68 -62 63 -106 64 -106 74 94 c41 52 76 98 78 103 1 4
-30 37 -70 73 l-73 66 -68 -62z m130 -27 c51 -49 52 -51 37 -75 -9 -13 -19
-24 -23 -24 -4 0 -14 -12 -24 -27 -9 -16 -26 -39 -37 -52 -20 -23 -21 -23 -35
-5 -8 10 -29 47 -47 82 l-31 63 43 44 c25 25 49 45 55 45 5 0 34 -23 62 -51z"
      />
      <path d="M680 2319 c-36 -44 -66 -83 -68 -87 -3 -9 124 -175 137 -180 4 -1 26
36 48 84 l42 86 -41 89 c-22 49 -44 89 -47 89 -3 -1 -35 -37 -71 -81z m94 -11
c10 -24 23 -53 28 -65 8 -17 4 -35 -22 -89 l-33 -67 -19 24 c-11 13 -24 31
-31 39 -41 51 -57 73 -57 79 0 3 10 18 23 32 12 15 35 43 51 62 17 20 33 34
36 32 4 -2 15 -23 24 -47z"
      />
      <path d="M1678 2308 l-65 -82 65 -83 c36 -46 68 -80 72 -76 3 5 37 43 75 86
l68 77 -74 78 c-40 42 -74 78 -74 80 -1 1 -31 -35 -67 -80z m126 -142 c-31
-36 -61 -63 -66 -60 -13 8 -88 112 -88 122 0 6 21 35 47 66 l46 57 59 -60 60
-61 -58 -64z"
      />
      <path d="M158 2303 l-57 -76 40 -56 c23 -31 48 -68 58 -83 9 -16 18 -28 21
-27 3 0 37 37 76 83 l71 81 -71 78 c-39 43 -73 77 -76 77 -3 -1 -31 -35 -62
-77z m171 -78 c0 -5 -24 -36 -53 -69 l-53 -58 -46 63 c-50 71 -51 65 17 151
l29 37 53 -57 c29 -31 53 -61 53 -67z"
      />
      <path d="M2694 2302 l-61 -76 47 -57 c26 -31 55 -67 64 -80 l16 -23 36 34 c20
19 54 56 75 82 l40 47 -72 76 c-40 41 -75 75 -78 74 -3 0 -34 -35 -67 -77z
m109 5 c18 -18 42 -45 54 -58 l23 -25 -54 -57 c-30 -31 -57 -57 -60 -57 -2 0
-25 25 -51 55 -32 36 -45 60 -41 72 6 19 79 103 90 103 4 0 21 -15 39 -33z"
      />
      <path d="M2451 2031 c-28 -44 -51 -85 -51 -90 0 -6 26 -48 57 -94 l57 -84 21
26 c47 58 115 150 115 156 0 4 -29 39 -65 78 -36 39 -65 75 -65 79 0 24 -24
-1 -69 -71z m101 -3 c77 -84 76 -78 21 -152 -26 -36 -51 -65 -56 -66 -4 0 -27
30 -50 67 l-43 68 40 62 c22 35 42 63 44 63 3 0 23 -19 44 -42z"
      />
      <path d="M1435 1439 c-30 -42 -55 -80 -55 -85 0 -5 24 -46 54 -92 l53 -83 75
98 74 98 -73 70 -73 70 -55 -76z m119 -18 c32 -33 46 -54 40 -63 -6 -11 -66
-88 -95 -122 -10 -12 -28 8 -63 68 -28 49 -28 49 -11 80 28 47 59 85 70 85 6
1 32 -21 59 -48z"
      />
    </g>
  </LogoRoot>
);

export default Logo;
