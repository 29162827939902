import type { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Paper,
  Typography,
  Grid,
  Button
} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Events, Log } from 'src/Models/models';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';

interface DeviceHistoryProps {
  events : Events
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));




const actionDescription = (data) => {
  const result = data.map((d) => {
    switch (d.kind) {
      case 'E':
        return `Field ${d.path.join(',')} : modified data from ${d.lhs} to ${d.rhs}`;
      default:
        return '';
    }
  });
  return result.join(',');
};

const generateAction = (device : Log) => {
  switch (device.actionType) {
    case 'DeviceUpdate':
      return actionDescription(JSON.parse(device.data));
    case 'DeviceCreate':
    default:
      return '';
  }
};

const DevicetHistory: FC<DeviceHistoryProps> = (props) => {
  const classes = useStyles();
  const { events } = props;
  const getAction = (timestamp) => {
    const BasicComponentResult = events.BasicComponentsHistories.find(r => r.history_insertTime === timestamp);
    const DeviceResult = events.DevicesHistories.find(r => r.history_insertTime === timestamp);
    const MiscHardwareResult = events.MiscHardwareHistories.find(r => r.history_insertTime === timestamp);
    const SystemInfoResult = events.SystemInfoHistories.find(r => r.history_insertTime === timestamp);
    return fetchChanges(BasicComponentResult,DeviceResult,MiscHardwareResult,SystemInfoResult);
  }


  const fetchChanges = (BasicComponentResult,DeviceResult,MiscHardwareResult,SystemInfoResult) => {
    let result = [];
    for (const key in BasicComponentResult) {
      if(BasicComponentResult[key] && key !== "history_insertTime" && key !== "techTag"){
        result.push(<Typography>{key} updated to {BasicComponentResult[key]}</Typography>)
      }
  }
  for (const key in DeviceResult) {
    if(DeviceResult[key] && key !== "event_id" && key !== "techTag"  && key !== "history_insertTime"){
      result.push(<Typography>{key} updated to {DeviceResult[key]}</Typography>)
    }
}
for (const key in MiscHardwareResult) {
  if(MiscHardwareResult[key] && key !== "history_insertTime" && key !== "techTag"){
    result.push(<Typography>{key} updated to {MiscHardwareResult[key]}</Typography>)
  }
}
for (const key in SystemInfoResult) {
  if(SystemInfoResult[key] && key !== "history_insertTime" && key !== "techTag"){
    result.push(<Typography>{key} updated to {SystemInfoResult[key]}</Typography>)
  }
}
  return result;
  }
  return (
    <Card style={{ marginTop: '2%' }}>

      <Grid
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <CardHeader title="Device Snapshots" />
        </Grid>
       
      </Grid>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      >
        <Timeline align="alternate">
          {events.events.map((value) => (
            <TimelineItem key={value.time}>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper
                  elevation={3}
                  className={classes.paper}
                >
                  {value.eventType}
                  <Typography>{value.time}</Typography>
                  {getAction(value.time)}
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    </Card>
  );
};


export default DevicetHistory;
