import { Location } from 'src/Models/models';
import axios from '../lib/axios';

export const getAllLocations = async (): Promise<Location[]> => {
  return await (await axios.get('https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getAllLocations')).data;
};


export const createNewLocation = async(body) => {
  return await (await axios.post('https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/createLocation',body)).data;
};

export const getLocationById = async (id) => {
  return await (await axios.get(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getLocationById/${id}`)).data;
}

export const deleteLocationById = async (ids) => {
  return await (await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/deleteLocationById`,{
    locationIds : ids
  })).data;
}

export const deleteLotById = async (ids) => {
  return await (await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/deleteLotById`,{
    lotid : ids
  })).data;
}


export const removeDevicesFromLocationCall = async (devices,locationId) => {
  return await (await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/removeDevicesFromLocation`,{
    devices : devices,
    locationId : locationId
  })).data;
}

export const transferDevicesFromLocation = async (devices, newLocation) => {
  return await (await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/transferDevicesFromLocation`,{
    devices : devices,
    newLocation : newLocation
  })).data;
}


export const releaseDevicesFromLocation = async (devices) => {
  return await (await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/releaseDevicesFromLocation`,{
    devices : devices,
  })).data;
}


export const getUnAssingedDevicesCall = async () => {
  return await (await axios.get('https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getAllAvaiableDevices')).data;
}

export const getLocationOnlyById = async (id) => {
  return await (await axios.get(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getLocationOnlyById/${id}`)).data;
}

export const getMultipleLocationById = async (ids) => {
  return await (await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getMultipleLocationById`,{
  locationIds : ids
  })).data;
}



export const validateDeviceToLocation = async (tags) => {
  return await (await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/validateDeviceToLocation`,{
    techTags : tags,
  })).data;
}

export const bulkInsertLocation = async (tags,location) => {
  return await (await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/bulkInsertLocation`,{
    techTags : tags,
    location : location
  })).data;
}
