import { Card, CardHeader, Skeleton } from '@material-ui/core';
import type { FC } from 'react';

const SkletionLoader : FC = () => { 

return (
    <>
    <Card>
     <CardHeader
     avatar={(
      <Skeleton animation="wave" style={{ marginBottom: 6 }} variant="circular" width={40} height={40} />)}
      title={(<Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />)}

      subheader={(<Skeleton animation="wave" height={10} width="40%" />)}>
  </CardHeader>
  <CardHeader
     avatar={(
      <Skeleton animation="wave" style={{ marginBottom: 6 }} variant="circular" width={40} height={40} />)}
      title={(<Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />)}

      subheader={(<Skeleton animation="wave" height={10} width="40%" />)}>
  </CardHeader>
  <CardHeader
     avatar={(
      <Skeleton animation="wave" style={{ marginBottom: 6 }} variant="circular" width={40} height={40} />)}
      title={(<Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />)}

      subheader={(<Skeleton animation="wave" height={10} width="40%" />)}>
  </CardHeader>
  <CardHeader
     avatar={(
      <Skeleton animation="wave" style={{ marginBottom: 6 }} variant="circular" width={40} height={40} />)}
      title={(<Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />)}

      subheader={(<Skeleton animation="wave" height={10} width="40%" />)}>
  </CardHeader>
       <CardHeader
     avatar={(
      <Skeleton animation="wave" style={{ marginBottom: 6 }} variant="circular" width={40} height={40} />)}
      title={(<Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />)}

      subheader={(<Skeleton animation="wave" height={10} width="40%" />)}>
  </CardHeader>
  <CardHeader
     avatar={(
      <Skeleton animation="wave" style={{ marginBottom: 6 }} variant="circular" width={40} height={40} />)}
      title={(<Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />)}

      subheader={(<Skeleton animation="wave" height={10} width="40%" />)}>
  </CardHeader>
  <CardHeader
     avatar={(
      <Skeleton animation="wave" style={{ marginBottom: 6 }} variant="circular" width={40} height={40} />)}
      title={(<Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />)}

      subheader={(<Skeleton animation="wave" height={10} width="40%" />)}>
  </CardHeader>
  <CardHeader
     avatar={(
      <Skeleton animation="wave" style={{ marginBottom: 6 }} variant="circular" width={40} height={40} />)}
      title={(<Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />)}

      subheader={(<Skeleton animation="wave" height={10} width="40%" />)}>
  </CardHeader>

  </Card>
    </>
)
}

export default SkletionLoader;