import type { FC } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import { HDDReportProps } from 'src/Models/models';
import Scrollbar from '../../Scrollbar';


const HddWipeTab: FC<HDDReportProps> = (props) => {
  const { wipeReport , device } = props;
  return (
    <Paper >
      <Scrollbar>
        <Box
          sx={{
            minWidth: 800,
            p: 6
          }}
        >
          <Grid
            container
            justifyContent="space-between"
          >
            <Grid item>
              <img
                style={{ width: '250px', paddingBottom: '15px' }}
                alt="logo"
                src={`data:image/jpeg;base64,${device.ownerLogo.profilepic_base64}`}
              />
              <Typography
                color="textPrimary"
                variant="h5"
              >
                DATA ERASURE REPORT
              </Typography>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Organization that carried out the wipe: {device.ownerLogo.name}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ my: 4 }}>
            <Grid
              container
              justifyContent="space-between"
            >
              <Grid item>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h6"
                >
                  Hardware Details:
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  Serial Number:
                  {' '}
                  {device['SystemInfo.Serial']}
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  Make:
                  {' '}
                  {device['SystemInfo.Make']}
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  Model:
                  {' '}
                  {device['SystemInfo.Model']}
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  CPU:
                  {' '}
                  {device['BasicComponent.CpuTitle']}
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  Memory:
                  {' '}
                  {device['BasicComponent.RamTitle']}
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  HDD:
                  {' '}
                  {device['BasicComponent.StorageAmount']}
                </Typography>

              </Grid>
            </Grid>
          </Box>
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h6"
          >
            Data Erasure Log:
          </Typography>
          {wipeReport.map((r,index) => (
            <Box
             key={r["HddWipeReport.id"]}
              sx={{ my: 4 }}
            >
              <Typography color="textPrimary" variant="body2">{index+1}.</Typography>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="body2"
              >
                Name:
                {' '}
                {r["HddWipeReport.Name"]}
              </Typography>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="body2"
              >
                Passes:
                {' '}
                {r["HddWipeReport.Passes"]}
              </Typography>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="body2"
              >
                Size:
                {' '}
                {r["HddWipeReport.Size"]}
              </Typography>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="body2"
              >
                HDD Type:
                {' '}
                {r["HddWipeReport.Type"]}
              </Typography>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="body2"
              >
                Conclusion:
                {' '}
                {r["HddWipeReport.Conclusion"]}
              </Typography>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="body2"
              >
                Duration:
                {' '}
                {r["HddWipeReport.Duration"]}
              </Typography>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="body2"
              >
                Status:
                {' '}
                {r["HddWipeReport.Status"]}
              </Typography>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="body2"
              >
                Wipe Performed at:
                {' '}
                {r["HddWipeReport.createTimeinUTC"]}
              </Typography>
            </Box>
          ))}
        </Box>
      </Scrollbar>
    </Paper>
  );
};

HddWipeTab.propTypes = {
};

export default HddWipeTab;
