import { Button } from "@material-ui/core";
import { FC } from "react";
import AddIcon from '@mui/icons-material/Add';
import { transferDevicesFromLot } from "src/dataservies/lotDataservice";



type addToLotButtonProps = {
    selectedDevices : String[];
    lotId : string;
  }
  
const  AddToLotButton :FC<addToLotButtonProps>  = (props) => {

    const addToLocation = async () => {
        await transferDevicesFromLot(props.selectedDevices,props.lotId);
        location.reload();
      }

    return (<Button size='small' startIcon={<AddIcon />} disabled={props.selectedDevices.length === 0} onClick={addToLocation} autoFocus>
    Add {props.selectedDevices.length} devices to lot {props.lotId}
  </Button>)
  }

  export default AddToLotButton;