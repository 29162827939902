import React, { Fragment } from "react";
import { Box, CardHeader, Grid, Checkbox, Select, MenuItem, TextField, FormHelperText } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Formik } from "formik";
import shortid from "shortid";

const FeaturesOptions = ["WideScreen", "Adaptiv Synch", "3R Ready", "Full HD", "Anti-Glare", "Curved Screen ", "Built-In Speakers", "Built-In TV Tuner", "Other"];

const MiscLaptop = ({ device, handleChange, edit, handleTextChange,passport }) => {
  return (
    <Formik initialValues={{}} onSubmit={async (): Promise<void> => {}}>
      {(): JSX.Element => (
        <form>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              m: -1,
              p: 2,
            }}
          >
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <CardHeader title="Device Info" />
              </Grid>
            </Grid>
            <Grid style={{ marginLeft: "5px" }} container spacing={1}>
              <Grid item xs={3}>
                <TextField name="MonitorMiscHardware.grade" value={device["MonitorMiscHardware.grade"] ?? ""} disabled={!edit} onChange={handleTextChange} label="Grade" size="small" />
              </Grid>

              <Grid item  xs={6}>
                <TextField
                  name="MonitorMiscHardware.videoInput"
                  value={device["MonitorMiscHardware.videoInput"] ?? ""}
                  disabled={!edit}
                  onChange={handleTextChange}
                  label="Video Inputs"
                  size="small"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="MonitorMiscHardware.brightness"
                  value={device["MonitorMiscHardware.brightness"] ?? ""}
                  disabled={!edit}
                  onChange={handleTextChange}
                  label="Brightness"
                  size="small"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="MonitorMiscHardware.aspectRatio"
                  value={device["MonitorMiscHardware.aspectRatio"] ?? ""}
                  disabled={!edit}
                  onChange={handleTextChange}
                  label="Aspect Ratio"
                  size="small"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="MonitorMiscHardware.contrastRatio"
                  value={device["MonitorMiscHardware.contrastRatio"] ?? ""}
                  disabled={!edit}
                  onChange={handleTextChange}
                  label="Contrast Ratio"
                  size="small"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="MonitorMiscHardware.maxResuo"
                  value={device["MonitorMiscHardware.maxResuo"] ?? ""}
                  disabled={!edit}
                  onChange={handleTextChange}
                  label="Max Resolution"
                  size="small"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="MonitorMiscHardware.refreshRate"
                  value={device["MonitorMiscHardware.refreshRate"] ?? ""}
                  disabled={!edit}
                  onChange={handleTextChange}
                  label="Refresh Rate"
                  size="small"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="MonitorMiscHardware.responseTime"
                  value={device["MonitorMiscHardware.responseTime"] ?? ""}
                  disabled={!edit}
                  onChange={handleTextChange}
                  label="Response Time"
                  size="small"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="MonitorMiscHardware.displayType"
                  value={device["MonitorMiscHardware.displayType"] ?? ""}
                  disabled={!edit}
                  onChange={handleTextChange}
                  label="Display Type"
                  size="small"
                />
              </Grid>

              <Grid key={shortid.generate()} item xs={3}>
                <TextField
                value={device["MonitorMiscHardware.touchScreen"] ?? ""}
                name="MonitorMiscHardware.touchScreen"
                disabled={!edit}
                onChange={handleTextChange}
                  label="Touchscreen"
                  size="small"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField name="MonitorMiscHardware.color" value={device["MonitorMiscHardware.color"] ?? ""} disabled={!edit} onChange={handleTextChange} label="Color" size="small" />
              </Grid>

              <Grid item xs={3}>
                <TextField name="MonitorMiscHardware.manufeactureDate" value={device["MonitorMiscHardware.manufeactureDate"] ?? ""} disabled={!edit} onChange={handleTextChange} label="Manufacturer Date" size="small" />
              </Grid>

              <Grid key={shortid.generate()} item xs={3}>
                <Select onChange={handleTextChange} name="MonitorInfo.features" variant="outlined" size="small" value={device["MonitorInfo.features"]} disabled={!edit} fullWidth>
                  {FeaturesOptions.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>Features</FormHelperText>
              </Grid>
            </Grid>

            <Grid xs={12} style={{ marginTop: "20px" }}>
              {passport ? null : (<TextField
                name="description"
                value={device["description"] ?? ""}
                disabled={!edit}
                onChange={handleTextChange}
                label="Description"
                size="medium"
                minRows="2"
                maxRows="4"
                multiline
                fullWidth
              />)}
            </Grid>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default MiscLaptop;
