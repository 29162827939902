import { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import { Box } from '@material-ui/core';

const LoadingScreen: FC = () => {
  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        minHeight: '100%'
      }}
    >
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-100px',
      }}
      >
        <img
          style={{ width: '230px', paddingBottom: '15px' }}
          alt="logo"
          src="https://firebasestorage.googleapis.com/v0/b/tech-reuse.appspot.com/o/TechSpec-logo%20(1).png?alt=media&token=e572dddf-17c4-4383-aa62-43aef977b0ba"
        />
      </div>
    </Box>
  );
};

export default LoadingScreen;
