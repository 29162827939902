import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import { DeviceV2 } from 'src/Models/models';
import {
  Card,
  CardHeader,
  CardContent,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'src/lib/axios';
import { useSnackbar } from 'notistack';
import { genUploadImageText, getBase64 } from 'src/utils/shareFunctions';
import { StorageImage } from 'reactfire';
import FileDropzone from '../../FileDropzone';
import 'firebase/storage';
import firebase from '../../../lib/firebase';
import { deleteImage } from 'src/dataservies/blobStoragedataserivce';

interface UploadDeviceImageProps {
  device : DeviceV2;
  edit: boolean;
}

const UploadDeviceImage: FC<UploadDeviceImageProps> = (props) => {
  const { device, edit } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState<any[]>([]);
  const [imagesData, setImagesData] = useState<any[]>([]);
  const handledeleteImage = async (path) => {
   await deleteImage(path);
   location.reload();
  }
  const handleDrop = (newFiles: any): void => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };
  const handleImageOnClick = (e) => {
    window.open(e.target.src);
  };
  const handleRemove = (file): void => {
    setFiles((prevFiles) => prevFiles.filter((_file) => _file.path
      !== file.path));
  };
  const listFiles = useCallback(async () => {
    try {
      const result = await firebase.storage().ref().child(`devices/${device.techTag}`).listAll();
      setImagesData(result.items.map((item) => item.fullPath));
    } catch (err) {
      console.error(err);
    }
  }, []);
  const handleUpload = (): void => {
    files.forEach((file : File) => {
      getBase64(file, async (base64) => {
        await axios.post('https://us-central1-techreusetoolkit.cloudfunctions.net/publicApi/public/uploadDeviceImage', {
          techTag: device.techTag,
          fileName: file.name,
          baseString: base64,
          fileType: file.type
        });
        enqueueSnackbar('Image Uploaded', {
          autoHideDuration: 3000,
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'success'
        });
      });
    });
    setFiles([]);
  };
  const handleRemoveAll = (): void => {
    setFiles([]);
  };
  useEffect(() => {
    listFiles();
  }, [listFiles]);
  return (
    <Card>
      <CardHeader title={genUploadImageText(edit)} />
      <CardContent>
        {imagesData.map((item) => (
         <>
         {edit &&<p><Button onClick={ () => {handledeleteImage(item)}}>Delete</Button></p>}
          <StorageImage
            key={item}
            style={{ width: '25%', cursor: 'pointer' }}
            storagePath={item}
            alt={item}
            onClick={handleImageOnClick}
          />
         </>
        ))}

        {edit && (
        <FileDropzone
          accept="image/*"
          files={files}
          onDrop={handleDrop}
          onRemove={handleRemove}
          onRemoveAll={handleRemoveAll}
          onUpload={handleUpload}
        />
        )}

      </CardContent>
    </Card>
  );
};

UploadDeviceImage.propTypes = {
  // @ts-ignore
  device: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired
};

export default UploadDeviceImage;
