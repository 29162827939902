import { StepIconClasskey } from "@material-ui/core";

export interface RefurbisherCompany {
  customers: Customer[];
  address: string;
  id: string;
  company: string;
  email: string;
  isDisabled: boolean;
  token?: string;
  employee: User[];
}

export interface User {
  uid: string;
  name: string;
  companyID: string;
  email: string;
  isDisabled: boolean;
  id?: string;
  type: string;
  token?: string;
}

export interface customClaims {
  role : string;
}

export interface metaData{
  creationTime : string,
  lastSignInTime : string
}

export interface Refurbisher {
  customClaims : customClaims,
  disabled: boolean,
  displayName : string,
  email : string,
  emailVerified : boolean,
  metaData : metaData,
  photoURL : string,
  uid : string
}

export interface Client {
  id: number;
  email: string;
  from: string;
  createTimeinUTC: string;
  status: string;
  name: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  org_name: string;
}

export interface Lots {
  id: string;
  notes?: string;
  value: number;
  createTimeinUTC: string;
  owner: string;
  totalDevices?: number;
}

export interface Customer extends User {
  lots: Lot[];
  refurbisherID: string;
  refurbisherName: string;
}

export interface Lot {
  id: string;
  value: number;
  devices: Device[];
  createTimeinUTC: string;
  notes: string;
}

export interface Counters {
  devices: number;
  techTag: number;
}

export interface Log {
  actionType: string;
  content: any;
  id: string;
  data?: any;
  time: string;
}

export enum DeviceType {
  Desktop = "desktop",
  Laptop = "laptop",
  Tablet = "Tablet",
  Monitor = "monitor",
  Mobile = "Mobile",
}

// might need to add more field, will get more details from Charles, lets just set id and name for now
export interface Owner {
  id: string;
  name: string;
}

export interface Monitor {
  "MonitorInfo.Model": string;
  "MonitorInfo.MonitorID": string;
  "MonitorInfo.Name": string;
  "MonitorInfo.PnpID": string;
  "MonitorInfo.Serial": string;
  "MonitorInfo.Size": string;
  "MonitorInfo.sizeHorCM": string;
  "MonitorInfo.sizeVerCM": string;
  "MonitorInfo.techTag": number;
  createTimeinUTC: string;
  description: string;
  deviceType: DeviceType;
  lastUpdateTimeInUTC: string;
  location: string;
  lotNumber: number;
  notes: string;
  owner: string;
  sku: string;
  status: string;
  techTag: number;
  value: number;
}

export interface Mobile extends Device {
  storageAmount: string;
  color: string;
  carrier: string;
}

interface ownerLogo {
  profilepic_base64: string;
  name: string;
}

export interface DeviceV2 {
  id: number;
  techTag: number;
  value: number;
  createTimeinUTC: string;
  notes: string;
  location: string;
  status: string;
  lotNumber: number;
  client_id: StepIconClasskey;
  deviceType: string;
  sku: string;
  description: string;
  hddWipeReport: string;
  timestamped : string;
  deviceFingerPrintHash: string;
  grade: string;
  recycled: string;
  "SystemInfo.Make"?: string;
  "SystemInfo.Model"?: string;
  "SystemInfo.Serial"?: string;
  "BasicComponent.CpuTitle"?: string;
  "BasicComponent.RamTitle"?: string;
  "BasicComponent.StorageAmount"?: string;
  "BasicComponent.gpu"?: string;
  "BasicComponent.operatingSystem"?: string;
  "MiscHardware.backLitKeyboard"?: number;
  "MiscHardware.bluetooth"?: number;
  "MiscHardware.camera3d"?: number;
  "MiscHardware.displayPort"?: number;
  "MiscHardware.ethernet"?: number;
  "MiscHardware.hdmi"?: number;
  "MiscHardware.lanCard"?: number;
  "MiscHardware.microHDMI"?: number;
  "MiscHardware.microUSB"?: number;
  "MiscHardware.minidisplayport"?: number;
  "MiscHardware.rugged"?: number;
  "MiscHardware.touchScreen"?: number;
  "MiscHardware.usb1"?: number;
  "MiscHardware.usb2"?: number;
  "MiscHardware.usb3"?: number;
  "MiscHardware.color"?: string;
  "MiscHardware.storageType"?: string;
  qrHash?: string;
  ownerLogo?: ownerLogo;
}

export interface Location {
  id?: number;
  name: string;
  owner?: string;
  description?: string;
  createTimeinUTC?: string;
  "Device.totalDevices"?: number;
}

export interface Device {
  deviceType?: DeviceType;
  timeInUTC: string;
  // the value in dollar of the device
  value: number;
  owner?: Owner;
  notes?: string;
  status: string;
  location?: string;
  lotNumber?: string;
  BasicComponents?: BasicComponents;
  SystemInfo?: SystemInfo;
  MiscHardware?: MiscHardware;
  techTag: string;
  sku?: string;
}

export interface BasicComponents {
  CpuTitle: string;
  RamTitle: string;
  StorageAmount: string;
  StorageType: string;
  operatingSystem: string;
  gpu: string;
}

export enum TowerSize {
  Small = "Ultra Small Form",
  Mini = "Mini Tower",
  Mid = "Mid Tower",
  Full = "Full Tower",
}

export interface Desktop extends Device {
  TowerSize: TowerSize;
  Type: string;
  Color: string;
}

export interface Laptop extends Device {
  Color: string;
  ScreenSize: string;
}

export interface SystemInfo {
  Make: string;
  Model: string;
  Serial: string;
}

export interface MiscHardware {
  backLitKeyboard: boolean;
  bluetooth: boolean;
  camera3d: boolean;
  color: string;
  displayPort: boolean;
  ethernet: boolean;
  hdmi: boolean;
  hsdpa: boolean;
  lanCard: boolean;
  microHDMI: boolean;
  microUSB: boolean;
  microphone: boolean;
  miniEthernet: boolean;
  miniUSB: boolean;
  minidisplayport: boolean;
  rugged: boolean;
  screensize: string;
  storageType: string;
  tabletPC: boolean;
  touchScreen: boolean;
  usb1: boolean;
  usb2: boolean;
  webcam: boolean;
}
export interface HDDReportProps {
  wipeReport: WipeReport[];
  device: DeviceV2;
  companyName: string;
}


export interface MultipleHDDReportProps {
  wipeReport: WipeReportV2[];
  device: DeviceV2[];
  company: {
    name : string,
    profilepic_base64 : string
  };
}

export interface WipeReportV2 {
  "id": number;
  "techTag": number;
  "Name": string;
  "Hddserial": string;
  "createTimeinUTC": string;
  "Type": string;
  "Size": string;
  "Status": string;
  "Passes": string;
  "Conclusion": string;
  "Duration": string;
}

export interface WipeReport {
  "HddWipeReport.id": number;
  "HddWipeReport.techTag": number;
  "HddWipeReport.Name": string;
  "HddWipeReport.Hddserial": string;
  "HddWipeReport.createTimeinUTC": string;
  "HddWipeReport.Type": string;
  "HddWipeReport.Size": string;
  "HddWipeReport.Status": string;
  "HddWipeReport.Passes": string;
  "HddWipeReport.Conclusion": string;
  "HddWipeReport.Duration": string;
}

export interface HDDWipeReport {
  tag: number;
  duration: string;
  hddSerial: string;
  name: string;
  passes: string;
  size: string;
  status: string;
  timeInUTC: string;
  type: string;
  lot: string;
  downloadURL: string;
  hash: string;
  id: string;
  notes: string;
}

export enum ActionType {
  DeviceCreate = "DeviceCreate",
  DeviceDelete = "DeviceDelete",
  NewRefurbisher = "NewRefurbisher",
  RefurbisherDeleted = "RefurbisherDeleted",
}

export interface Events {
  BasicComponentsHistories : BasicComponentsHistory[],
  DevicesHistories : DevicesHistory[],
  MiscHardwareHistories : MiscHardwareHistory[],
  SystemInfoHistories : SystemInfoHistory[],
  events : event[],
}

export interface event {
  client_id: string;
description: string;
eventType: string;
event_id: string;
id: number;
owner: string;
techtag: number;
time: string;
}

export interface SystemInfoHistory {
  Carrier: string;
IMEI: string;
Make: string;
Model: string;
Serial: string;
formFactor: string;
history_insertTime: string;
mobileType: string;
releaseYear: string;
techTag: number;
}

export interface BasicComponentsHistory {
  CpuTitle:  string;
RamTitle:  string;
StorageAmount:  string;
gpu:  string;
history_insertTime:  string;
memorySpeed:  string;
memoryType:  string;
operatingSystem:  string;
opticalDrive:  string;
techTag: number;
}

export interface MiscHardwareHistory {
  activationLock: number;
backLitKeyboard: number;
batteryStatus: string;
bluetooth: number;
camera3d: number;
carrierLock: number;
color: number;
displayPort: number;
ethernet: number;
hdmi: number;
history_insertTime: string;
lanCard: number;
managementLocked: number;
microHDMI: number;
microUSB: number;
minidisplayport: number;
rugged: number;
screenSize: string;
storageType: number;
techTag: number;
touchScreen: number;
usb1: number;
usb2: number;
usb3: number;
webcam: number;
}

export interface DevicesHistory {
  client_id: string;
description: string;
deviceType: string;
event_id: string;
grade: string;
hddWipeReport: string;
history_insertTime: string;
location: string;
lotNumber: string;
notes: string;
owner: string;
recycled: string;
sku: string;
status: string;
techTag: number;
value: string;
}