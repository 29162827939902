import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Typography, Box } from "@material-ui/core";
import { FC } from "react";
import { useState, useEffect, useCallback, forwardRef } from 'react';
import { transferDevicesFromLocation } from "src/dataservies/locationDataservice";
import { transferDevicesFromLot } from "src/dataservies/lotDataservice";

interface AddDevicesToLotDialogProps {
    validationSource : number[];
    lotID? : number;
    locationId? : number;
    open : boolean;
    importType: string;
    handleClose : (e:any) => void;
}

const AddDevicesToLotDialog: FC<AddDevicesToLotDialogProps> = (props) => {
    const { lotID,open, handleClose, importType, locationId,validationSource= []} = props;
const closeDialog = (e) => {
    handleClose(e)
    setlotError([])
}
const submitBulkAction  = async () => {
    let numTTArr = techTags.map(Number);
    let duplicateTT = numTTArr.filter(x => !validationSource.includes(x)) ;
    if(duplicateTT.length > 0){
      setlotError(duplicateTT);
      return;
    }
    else{
      if(importType === "location"){
        await transferDevicesFromLocation(numTTArr,locationId);
      }else{
        await transferDevicesFromLot(numTTArr,lotID);
      }

     window.location.reload();
    }
}


const [techTags, setTechTags] = useState<number[] | null>([]);
const [lotError, setlotError] = useState<string[] | null | number[]>([]);
const onTechTagChange = (e) => {
    setTechTags(e.target.value.split('\n'));
}

return (

     <Dialog
     fullWidth={true}
        maxWidth={"sm"}
        open={open}
      >
        <DialogTitle>
          {`Add Devices to ${importType}: ${importType === "location" ? locationId : lotID }`}
          
        </DialogTitle>
        <Box sx={{ml : 3}}>
        <Typography>Please enter only one TechTag perline, with no comma (,) needed</Typography>
        </Box>
        <DialogContent>
          <TextField
          fullWidth
          id="outlined-multiline-static"
          label="Enter or Scan TechTags here"
          multiline
          onChange={onTechTagChange}
          rows={10}
        />
        <Box sx={{mt : 2}}>
        {lotError.map(o => (<Typography color='red'>Error: <b>{o}</b> is invalid or is already assigned to a {importType} </Typography>))}
        <b>Please remove above TechTags from the list and try again.</b>
        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
          <Button disabled={techTags.length === 0} onClick={submitBulkAction}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    
)

}


export default AddDevicesToLotDialog;
