import React, { Fragment } from "react";
import { Box, CardHeader, Grid, Checkbox, Select, MenuItem, TextField, FormHelperText } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Formik } from "formik";
import shortid from "shortid";

const OperatingSystemOptions = ["Windows", "Mac OS", "Chrome OS", "Linux", "No OS"];

const MiscOther = ({ device, handleChange, edit, handleTextChange,passport }) => {
  return (
    <Formik initialValues={{}} onSubmit={async (): Promise<void> => {}}>
      {(): JSX.Element => (
        <form>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              m: -1,
              p: 2,
            }}
          >
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <CardHeader title="Device Info" />
              </Grid>
            </Grid>
            <Grid style={{ marginLeft: "5px" }} container spacing={1}>
              <Grid item xs={3}>
                <TextField name="grade" value={device["grade"] ?? ""} disabled={!edit} onChange={handleTextChange} label="Grade" size="small" />
              </Grid>
            </Grid>

            <Grid xs={12} style={{ marginTop: "20px" }}>
              {passport ? null: (<TextField
                name="description"
                value={device["description"] ?? ""}
                disabled={!edit}
                onChange={handleTextChange}
                label="Description"
                size="medium"
                minRows="2"
                maxRows="4"
                multiline
                fullWidth
              />)}
            </Grid>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default MiscOther;
