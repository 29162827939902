import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Modal, Select, Typography } from "@material-ui/core";
import { FC } from "react";
import AddIcon from '@mui/icons-material/Add';
import * as React from 'react';
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { getAllStatus, getTechTagByLocations, updateStatusByTechTags } from "src/dataservies/deviceDataservice";
import { DeviceV2 } from "src/Models/models";
import { arrayToCSV, downloadCSV } from "src/utils/shareFunctions";

type updateStatusProps = {
    selectedDevices : DeviceV2[];
    mode? : String
  }
  
const  ExportOtherPlatFormButton :FC<updateStatusProps>  = (props) => {

  const [selectedPlatform, setSelectedPlatform] = React.useState('');
  const [Platforms,setPlatforms] = React.useState<string[]>(["Tradeloop","SellBrite"]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedPlatform(event.target.value.toString());
  };

      const exportCSV = async () => {

        let rawcsvdata ;


        if(props.mode === "location"){
          rawcsvdata = await getTechTagByLocations(props.selectedDevices);
        }else{
          rawcsvdata = props.selectedDevices;
        }
        
        
        if(selectedPlatform === "Tradeloop"){
          const data = rawcsvdata.map(d => {
            return {
              "part number" : d["SystemInfo.Model"],
              "description" : `${d["SystemInfo.Make"]} ${d["SystemInfo.Model"]} ${d["BasicComponent.CpuTitle"]} ${d["BasicComponent.StorageAmount"]}GB HDD ${d["BasicComponent.RamTitle"]}GB Memory`,
              "wholesale price" : "",
              "quantity" : "",
              "manufacturer" : d["SystemInfo.Make"],
              "condition" : d.grade,
              "retail price" : "",
              "serial number" : d["SystemInfo.Serial"],
              "Lot" : d.location,

            }
           

          });
          const csvdata = arrayToCSV(data);
          downloadCSV(csvdata,"tradeLoop.csv")
        }
        if (selectedPlatform === "SellBrite"){
        
          const data = rawcsvdata.map(d => {
            return {
              "sku": `${d["SystemInfo.Make"]} ${d["SystemInfo.Model"]} ${d["BasicComponent.CpuTitle"]} ${d["BasicComponent.StorageAmount"]}GB HDD ${d["BasicComponent.RamTitle"]}GB Memory`,
              "parent_sku": "",
              "name": "ZBook Firefly G8 Mobile Workstation",
              "brand": d["SystemInfo.Make"],
              "condition":  "",
              "condition_note": "",
              "price": "",
              "notes": "",
              "category_name": "",
              "manufacturer": d["SystemInfo.Make"],
              "manufacturer_model_number": d["SystemInfo.Model"],
              "grade": d.grade,
              "hard_drive_size": `${d["BasicComponent.StorageAmount"]}GB`,
              "hard_drive_type": "",
              "memory_config": `${d["BasicComponent.RamTitle"]}GB`,
              "memory_speed": "",
              "memory_total": `${d["BasicComponent.RamTitle"]}GB`,
              "model": d["SystemInfo.Model"],
              "operating_system": "",
              "optical": "",
              "processor": d['BasicComponent.CpuTitle'],
              "ram_type": "",
              "screen": "",
              "screen_resolution": "",
              "serial": d["SystemInfo.Serial"],
              "speed": "",
              "video_card": ""
            }
          })

          const csvdata = arrayToCSV(data,`SELLBRITE PRODUCT CSV TEMPLATE (Do NOT remove the first 3 rows). You MAY delete or change the order of columns, but do NOT alter the header names in row 3. *Required Fields.,,,,,,,,,,,,,,,,,,,,,,,,,,
          SKU*,SKU of Parent Product,Product Name,Brand Name,"Condition (new, used, reconditioned)",Condition Note,Price,Notes,Sellbrite Category Name,Manufacturer,Manufacturer Model/Part Number,Grade,Hard Drive Size,Hard Drive Type,Memory Config,Memory Speed,Memory Total,Model,Operating System,Optical,Processor,RAM Type,Screen,Screen Resolution,Serial,Speed,Video Card
          sku,parent_sku,name,brand,condition,condition_note,price,notes,category_name,manufacturer,manufacturer_model_number,grade,hard_drive_size,hard_drive_type,memory_config,memory_speed,memory_total,model,operating_system,optical,processor,ram_type,screen,screen_resolution,serial,speed,video_card`);
          downloadCSV(csvdata,"sellBrite.csv")

        }
        
      }

    return (<>
    
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Select 3rd Party Platform"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Select
          fullWidth
          value={selectedPlatform}
          onChange={handleChange}
        >
         {Platforms.map((s,i) => (
           <MenuItem key={i} value={s}>{s}</MenuItem>
         ))} 
          </Select>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={exportCSV} color="primary" autoFocus>
            Select
          </Button>
        </DialogActions>
      </Dialog>
    
    <Button size='small' disabled={props.selectedDevices.length === 0} onClick={handleOpen} autoFocus>
    Export to 3rd Party Platform
  </Button></>)
  }

  export default ExportOtherPlatFormButton;