export const laptopColumns = () => {
    return [
        { field: "BasicComponent.CpuTitle", headerName: "CPU", hide: false, renderCell: (data) => null },
        { field: "BasicComponent.StorageAmount", headerName: "Hard Drive", hide: false, renderCell: (data) => null },
        { field: "BasicComponent.RamTitle", headerName: "RAM", hide: false, renderCell: (data) => null },
        { field: "MiscHardware.ethernet", headerName: "Network Port", hide: true, renderCell: (data) => null },
        { field: "BasicComponent.operatingSystem", headerName: "Operating System", hide: true, renderCell: (data) => null },
        { field: "BasicComponent.opticalDrive", headerName: "Optical Drive", hide: true, renderCell: (data) => null },
        { field: "BasicComponent.memoryType", headerName: "Memory Type", hide: true, renderCell: (data) => null },
        { field: "BasicComponent.memorySpeed", headerName: "Memory Speed", hide: true, renderCell: (data) => null },
        { field: "SystemInfo.releaseYear", headerName: "Release Year", hide: true, renderCell: (data) => null },
        { field: "MonitorMiscHardware.maxResuo", headerName: "Resolution", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.screenSize", headerName: "Screen Size", hide: true, renderCell: (data) => null },
        { field: "sku", headerName: "SKU", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.storageType", headerName: "Storage Type", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.touchScreen", headerName: "Touchscreen", hide: true, renderCell: (data) => null },
        { field: "BasicComponent.gpu", headerName: "Video Card", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.webcam", headerName: "Webcam", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.backLitKeyboard", headerName: "Backlit Keyboard", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.batteryStatus", headerName: "Battery Status", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.bluetooth", headerName: "Bluetooth", hide: true, renderCell: (data) => null },
        { field: "url", headerName: "URL", hide: false, renderCell: (data) => null},
        { field: "MiscHardware.color", headerName: "Color", hide: true, renderCell: (data) => null },
        { field: "grade", headerName: "Grade", hide: false, renderCell: (data) => null },

        { field: "status", headerName: "Status", hide: false, renderCell: (data) => null },
      ];
}

export const desktopColumns = () => {
    return [
        { field: "BasicComponent.CpuTitle", headerName: "CPU", hide: false, renderCell: (data) => null },
        { field: "BasicComponent.StorageAmount", headerName: "Hard Drive", hide: false, renderCell: (data) => null },
        { field: "BasicComponent.RamTitle", headerName: "RAM", hide: false, renderCell: (data) => null },
        { field: "SystemInfo.formFactor", headerName: "Form Factor", hide: false, renderCell: (data) => null },
        { field: "MiscHardware.bluetooth", headerName: "Bluetooth", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.color", headerName: "Color", hide: true, renderCell: (data) => null },
        { field: "description", headerName: "Description", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.displayPort", headerName: "Display Port", hide: true, renderCell: (data) => null },
        { field: "grade", headerName: "Grade", hide: false, renderCell: (data) => null },
        { field: "MiscHardware.storageType", headerName: "Hard Drive Type", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.hdmi", headerName: "HDMI", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.ethernet", headerName: "Network port", hide: true, renderCell: (data) => null },
        { field: "BasicComponent.operatingSystem", headerName: "Operating System", hide: true, renderCell: (data) => null },
        { field: "BasicComponent.opticalDrive", headerName: "Optical Drive", hide: true, renderCell: (data) => null },
        { field: "BasicComponent.memoryType", headerName: "Memory Type", hide: true, renderCell: (data) => null },
        { field: "BasicComponent.memorySpeed", headerName: "Memory Speed", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.screenSize", headerName: "Screen Size", hide: true, renderCell: (data) => null },
        { field: "sku", headerName: "SKU", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.touchScreen", headerName: "Touchscreen", hide: true, renderCell: (data) => null },
        { field: "BasicComponent.gpu", headerName: "Video Card", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.webcam", headerName: "Webcam", hide: true, renderCell: (data) => null },
        { field: "url", headerName: "URL", hide: false, renderCell: (data) => null},
        { field: "status", headerName: "Status", hide: false, renderCell: (data) => null },
      ]
}

export const monitorColumns = () => {
    return [
        { field: "MiscHardware.screenSize", headerName: "Screen Size", hide: false, renderCell: (data) => null },
        { field: "MiscHardware.hdmi", headerName: "HDMI", hide: false, renderCell: (data) => null },
        { field: "MonitorMiscHardware.brightness", headerName: "Brightness", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.color", headerName: "Color", hide: true, renderCell: (data) => null },
        { field: "description", headerName: "Description", hide: true, renderCell: (data) => null },
        { field: "MonitorMiscHardware.contrastRatio", headerName: "Contrast Ratio", hide: true, renderCell: (data) => null },
        { field: "MonitorMiscHardware.displayType", headerName: "Display Type", hide: true, renderCell: (data) => null },
        { field: "MonitorMiscHardware.aspectRatio", headerName: "Aspect Ratio", hide: true, renderCell: (data) => null },
        { field: "MonitorInfo.features", headerName: "Features", hide: true, renderCell: (data) => null },
        { field: "grade", headerName: "Grade", hide: false, renderCell: (data) => null },
        { field: "MonitorMiscHardware.maxResuo", headerName: "Max Resolution", hide: true, renderCell: (data) => null },
        { field: "MonitorMiscHardware.refreshRate", headerName: "Refresh Rate", hide: true, renderCell: (data) => null },
        { field: "MonitorMiscHardware.responseTime", headerName: "Response Time", hide: true, renderCell: (data) => null },
        { field: "sku", headerName: "SKU", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.touchScreen", headerName: "Touchscreen", hide: true, renderCell: (data) => null },
        { field: "MonitorMiscHardware.videoInput", headerName: "Video Inputs", hide: true, renderCell: (data) => null },
        { field: "status", headerName: "Status", hide: true, renderCell: (data) => null },
      ]
}

export const mobileColumns = () => {
    return [
        { field: "SystemInfo.mobileType", headerName: "Mobile Type", hide: false, renderCell: (data) => null },
        { field: "BasicComponent.StorageAmount", headerName: "Storage Size", hide: false, renderCell: (data) => null },
        { field: "SystemInfo.Carrier", headerName: "Carrier", hide: false, renderCell: (data) => null },
        { field: "MiscHardware.color", headerName: "Color", hide: true, renderCell: (data) => null },
        { field: "SystemInfo.IMEI", headerName: "IMEI", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.activationLock", headerName: "Activation Lock", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.carrierLock", headerName: "Carrier Lock", hide: true, renderCell: (data) => null },
        { field: "MiscHardware.managementLocked", headerName: "Management Locked", hide: true, renderCell: (data) => null },
        { field: "grade", headerName: "Grade", hide: false, renderCell: (data) => null },
        { field: "MiscHardware.screenSize", headerName: "Screen Size", hide: true, renderCell: (data) => null },
        { field: "description", headerName: "Description", hide: true, renderCell: (data) => null },
        { field: "status", headerName: "Status", hide: true, renderCell: (data) => null },
      ];
}

export const otherColumns = () => {
    return [
        { field: "description", headerName: "Description", hide: false, renderCell: (data) => null },
        { field: "status", headerName: "Status", hide: true, renderCell: (data) => null },
      ];
}

export const locationColumns =() => {
  return [
    { field: "techtag", headerName: "TechTag", hide: false, renderCell: (data) => null },
    { field: "value", headerName: "Value", hide: false, renderCell: (data) => null },
    { field: "SystemInfo.Make", headerName: "Make", hide: false, renderCell: (data) => null },
    { field: "SystemInfo.Model", headerName: "Model", hide: false, renderCell: (data) => null },
    { field: "SystemInfo.Serial", headerName: "Serial", hide: false, renderCell: (data) => null },
    { field: "deviceType", headerName: "Device Type", hide: false, renderCell: (data) => null },
    { field: "status", headerName: "Status", hide: false, renderCell: (data) => null }
  ];
}


export const fulldetailColumns = () => {
  return [

      { field: "BasicComponent.StorageAmount", headerName: "Hard Drive", hide: false, renderCell: (data) => null },
      { field: "BasicComponent.RamTitle", headerName: "RAM", hide: false, renderCell: (data) => null },
      { field: "MiscHardware.ethernet", headerName: "Network Port", hide: true, renderCell: (data) => null },
      { field: "BasicComponent.operatingSystem", headerName: "Operating System", hide: true, renderCell: (data) => null },
      { field: "BasicComponent.opticalDrive", headerName: "Optical Drive", hide: true, renderCell: (data) => null },
      { field: "BasicComponent.memoryType", headerName: "Memory Type", hide: true, renderCell: (data) => null },
      { field: "BasicComponent.memorySpeed", headerName: "Memory Speed", hide: true, renderCell: (data) => null },
      { field: "SystemInfo.releaseYear", headerName: "Release Year", hide: true, renderCell: (data) => null },
      { field: "MonitorMiscHardware.maxResuo", headerName: "Resolution", hide: true, renderCell: (data) => null },
      { field: "MiscHardware.screenSize", headerName: "Screen Size", hide: true, renderCell: (data) => null },
      { field: "sku", headerName: "SKU", hide: true, renderCell: (data) => null },
      { field: "MiscHardware.storageType", headerName: "Storage Type", hide: true, renderCell: (data) => null },
      { field: "MiscHardware.touchScreen", headerName: "Touchscreen", hide: true, renderCell: (data) => null },
      { field: "BasicComponent.gpu", headerName: "Video Card", hide: true, renderCell: (data) => null },
      { field: "MiscHardware.webcam", headerName: "Webcam", hide: true, renderCell: (data) => null },
      { field: "MiscHardware.backLitKeyboard", headerName: "Backlit Keyboard", hide: true, renderCell: (data) => null },
      { field: "MiscHardware.batteryStatus", headerName: "Battery Status", hide: true, renderCell: (data) => null },
      { field: "MiscHardware.bluetooth", headerName: "Bluetooth", hide: true, renderCell: (data) => null },
      { field: "MiscHardware.color", headerName: "Color", hide: true, renderCell: (data) => null },
      { field: "status", headerName: "Status", hide: true, renderCell: (data) => null },
      { field: "SystemInfo.mobileType", headerName: "Mobile Type", hide: true, renderCell: (data) => null },
      { field: "SystemInfo.Carrier", headerName: "Carrier", hide: true, renderCell: (data) => null },
      { field: "SystemInfo.IMEI", headerName: "IMEI", hide: true, renderCell: (data) => null },
      { field: "MiscHardware.activationLock", headerName: "Activation Lock", hide: true, renderCell: (data) => null },
      { field: "MiscHardware.carrierLock", headerName: "Carrier Lock", hide: true, renderCell: (data) => null },
      { field: "MiscHardware.managementLocked", headerName: "Management Locked", hide: true, renderCell: (data) => null },
      { field: "grade", headerName: "Grade", hide: false, renderCell: (data) => null },
      { field: "description", headerName: "Description", hide: true, renderCell: (data) => null },
      { field: "MiscHardware.hdmi", headerName: "HDMI", hide: true, renderCell: (data) => null },
      { field: "MonitorMiscHardware.brightness", headerName: "Brightness", hide: true, renderCell: (data) => null },
      { field: "MonitorMiscHardware.contrastRatio", headerName: "Contrast Ratio", hide: true, renderCell: (data) => null },
      { field: "MonitorMiscHardware.displayType", headerName: "Display Type", hide: true, renderCell: (data) => null },
      { field: "MonitorMiscHardware.aspectRatio", headerName: "Aspect Ratio", hide: true, renderCell: (data) => null },
      { field: "MonitorInfo.features", headerName: "Features", hide: true, renderCell: (data) => null },
      { field: "MonitorMiscHardware.refreshRate", headerName: "Refresh Rate", hide: true, renderCell: (data) => null },
      { field: "MonitorMiscHardware.responseTime", headerName: "Response Time", hide: true, renderCell: (data) => null },
      { field: "MonitorMiscHardware.videoInput", headerName: "Video Inputs", hide: true, renderCell: (data) => null },
      { field: "url", headerName: "URL", hide: false, renderCell: (data) => null},
    ];
}