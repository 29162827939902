import axios from 'src/lib/axios';
import { Device } from 'src/Models/models';
import firebase from '../lib/firebase';
import { getCertificate } from 'src/dataservies/deviceDataservice';

export const deleteUserAccount = async (email : string) => {
  try {
    await axios.post('https://us-central1-techreusetoolkit.cloudfunctions.net/adminApi/admin/user/deleteUser', { email });
  } catch (e) {
    console.log(e);
  }
};


export const updateUserPassword = async (password : string) => {
  const user = await firebase.auth().currentUser;
  user.updatePassword(password).then(() => 'sucess').catch((error) => error);
};

export const createDownloadURL = (csvStr: string) => {
  const hiddenElement = document.createElement('a');
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvStr)}`;
  hiddenElement.target = '_blank';
  hiddenElement.download = 'output.csv';
  hiddenElement.click();
};

export const miscHardwareNameTextField = (key) => {
  switch (key) {
    case 'MiscHardware.color':
      return 'Color';
    case 'MiscHardware.storageType':
      return 'Storage Type';
    default:
      return '';
  }
};

export const miscHardwareName = (key) => {
  switch (key) {
    case 'MiscHardware.usb3':
      return 'USB 3.0';
    case 'MiscHardware.displayPort':
      return 'Display Port';
    case 'MiscHardware.hdmi':
      return 'HDMI';
    case 'MiscHardware.miniEthernet':
      return 'Mini Gigabit Ethernet';
    case 'MiscHardware.usb2':
      return 'USB 2.0';
    case 'MiscHardware.backLitKeyboard':
      return 'Backlit Keyboard';
    case 'MiscHardware.webcam':
      return 'Built-in Webcam';
    case 'MiscHardware.tabletPC':
      return 'Tablet PC';
    case 'MiscHardware.ethernet':
      return 'Gigabit Ethernet';
    case 'MiscHardware.microHDMI':
      return 'Micro-HDMI';
    case 'MiscHardware.miniUSB':
      return 'Mini-USB';
    case 'MiscHardware.lanCard':
      return '10/100 LAN Card';
    case 'MiscHardware.bluetooth':
      return 'Bluetooth';
    case 'MiscHardware.hsdpa':
      return 'HSDPA';
    case 'MiscHardware.touchScreen':
      return 'Touchscreen';
    case 'MiscHardware.minidisplayport':
      return 'Mini DisplayPort';
    case 'MiscHardware.microUSB':
      return 'Micro-USB';
    case 'MiscHardware.usb1':
      return 'USB 1.0/1.1';
    case 'MiscHardware.camera3d':
      return '3D Camera';
    case 'MiscHardware.microphone':
      return 'Built-in MicroPhone';
    case 'MiscHardware.rugged':
      return 'Rugged';
    default:
      return '';
  }
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result.toString().replace('data:', '').replace(/^.+,/, '')));
  reader.readAsDataURL(img);
};

export const genUploadImageText = (edit: boolean) => (edit ? 'Upload Image' : 'Device Images');

export const emptyModel: Device = {
  timeInUTC: '',
  value: 0,
  techTag: '',
  notes: '',
  status: '',
  sku: '',
  location: ''
};

export const random = (min: number, max: number) : number => Math.floor(Math.random() * (max - min)) + min;


export function csvToJson(csv) {
  const lines = csv.split('\n');
  const result = [];
  const headers = lines[1].split(',');

  for (let i = 2; i < lines.length; i++) {
      const obj = {};
      const currentline = lines[i].split(',');

      for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
      }

      result.push(obj);
  }

  return result // Converts the array of objects to a JSON string
}


export const downloadCSV = (csvString, filename)=> {
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const arrayToCSV = (objArray,strToAppend = "") => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  
  
  // Add column headers
  const headers = Object.keys(array[0]);
  strToAppend += headers.join(',') + '\r\n';
  
  // Add rows
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      if (line !== '') line += ','
      line += array[i][index];
    }
    strToAppend += line + '\r\n';
  }
  
  return strToAppend;
}

export const jsonTocsvAndDownload = (json) => {
  console.log(json);
  json = [json];
  const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
  const header = Object.keys(json[0])
  let csv = json.map(row => header.map(fieldName => 
  JSON.stringify(row[fieldName], replacer)).join(','))
  csv.unshift(header.join(','))
  csv = csv.join('\r\n')

  // Create link and download
  var link = document.createElement('a');
  link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
  link.setAttribute('download', "export");
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
 return csv;
}


export const convertJSONtocsv = (json) => {
  if (json.length === 0) {
      return;
  }

  json.sort(function(a,b){ 
     return Object.keys(b).length - Object.keys(a).length;
  });

  const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
  const header = Object.keys(json[0])
  let csv = json.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
  csv.unshift(header.join(','))
  csv = csv.join('\r\n')
  return csv;
}

export const downloadFingerPrintCertificate = async (deviceFingerPrintHash : string,coin) => {
  try {
    const downloadURL = await getCertificate(deviceFingerPrintHash, coin);
    if(downloadURL.error_message){
      return alert(downloadURL.error_message)
    }else{
      window.open(
        downloadURL.data.download_url, "_blank");
    }
    

  } catch (err) {
    console.error(err);
  }
}