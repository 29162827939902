import axios from '../lib/axios';

export const getAllDevicesByLot = async (lotId) => { return await (await axios.get(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getAllDevicesByLot/${lotId}`)).data;
};

export const createLot = async (input) => { return await (await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/createLot`,{
    notes: input.description
  })).data;
};

export const getAllLots = async () => {
    return await (await axios.get('https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getAllLots')).data;
  };

export const getUnassignedDevice = async () => {
    return await (await axios.get('https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getUnassignedDevice')).data;
}


export const getLotById = async(lotId) => {
    return await (await axios.get(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getLotById/${lotId}`)).data;
}

export const removeDevicesFromLotApiCall = async(devices, lotNumber) => {
    return await (await axios.post('https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/removeDevicesFromLot',{
      devices : devices,
      lotId : lotNumber
    })).data;
}

export const transferDevicesFromLot  = async(devices, lotNumber) => {
  return await (await axios.post('https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/transferDevicesFromLot',{
    devices : devices,
    lotId : lotNumber
  })).data;
}

export const transferLot  = async(lotId) => {
  return await (await axios.post('https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/generateTransferKey',{
    lotId : lotId
  })).data;
}

export const transferWithKey  = async(transferKey) => {
  return await (await axios.post('https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/transferWithKey',{
    transferKey : transferKey
  })).data;
}