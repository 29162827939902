import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import { DeviceV2 } from 'src/Models/models';
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'src/lib/axios';
import { useSnackbar } from 'notistack';
import { getBase64 } from 'src/utils/shareFunctions';
import FileDropzone from '../../FileDropzone';
import firebase from "firebase/app";
import { deleteImage } from 'src/dataservies/blobStoragedataserivce';
import Trash from 'src/icons/Trash';
import Clipboard from 'src/icons/Clipboard';

interface UploadOtherProps {
  device : DeviceV2;
  edit: boolean;
}



const UploadOther: FC<UploadOtherProps> = (props) => {
  const { device, edit } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState<any[]>([]);
  const [imagesData, setImagesData] = useState<any[]>([]);
  const handledeleteImage = async (path) => {
  
   await deleteImage(path.fullPath);
   enqueueSnackbar('File Deleted, please refresh to see changes', {
    autoHideDuration: 3000,
    anchorOrigin: {
      horizontal: 'right',
      vertical: 'top'
    },
    variant: 'success'
  });
  }
  const handleDrop = (newFiles: any): void => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };
  const handleDocumentOnClick = async (item) => {

    var findItem = imagesData.find((i)=> i.item.name === item.target.innerText);
 
    const storage = firebase.storage();
    const ref = storage.ref(findItem.item.fullPath);
  const url = await ref.getDownloadURL();

   window.open(url);
  };
  const handleRemove = (file): void => {
    setFiles((prevFiles) => prevFiles.filter((_file) => _file.path
      !== file.path));
  };
  const listFiles = useCallback(async () => {
    try {
      const result = await firebase.storage().ref().child(`devices/${device.techTag}/other`).listAll();
      const files = [];
      for (const item of result.items){
        let metaData = await item.getMetadata();
        files.push({
          item,
          metaData : metaData
        })
      }
      setImagesData(files);
    } catch (err) {
      console.error(err);
    }
  }, []);
  const handleUpload = (f): void => {
    files.forEach((file : any) => {
  
      getBase64(file, async (base64) => {
        await axios.post('https://us-central1-techreusetoolkit.cloudfunctions.net/publicApi/public/uploadDeviceDocument', {
          techTag: device.techTag,
          fileName: file.name,
          baseString: base64,
          fileType: file.type,
          description : f.find(f => f.path === file.path).description ?? ""
        });
        enqueueSnackbar('File Uploaded', {
          autoHideDuration: 3000,
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'success'
        });
      });
    });
    setFiles([]);
  };
  const handleRemoveAll = (): void => {
    setFiles([]);
  };
  useEffect(() => {
    listFiles();
  }, [listFiles]);
  return (
    <Card>
      <CardHeader title={edit ? "Upload Other Documents" : "Other Documents"} />
      <CardContent>
        {imagesData.map((item) => (
         <>

                <ListItem
                key={item.path}
                sx={{
                  border: 1,
                  borderColor: 'divider',
                  borderRadius: 1,
                  '& + &': {
                    mt: 1
                  }
                }}
              >
                <ListItemIcon>
                  <Clipboard fontSize="small" />
                </ListItemIcon>
                <ListItemText
              
                  primaryTypographyProps={{
                    color: 'textPrimary',
                    variant: 'subtitle2'
                  }}
                  secondary={item.metaData?.customMetadata?.description ?? ""}
                >  <a href='#' onClick={handleDocumentOnClick}>{item.item.name}</a>
                </ListItemText>
              {edit &&  <Tooltip title="Remove">
                  <IconButton
                    edge="end"
                    onClick={ () => {handledeleteImage(item.item)}}
                  >
                    <Trash fontSize="small" />
                  </IconButton>
                </Tooltip>}
              </ListItem>



  
  
         </>
        ))}

        {edit && (
        <FileDropzone
          files={files}
          onDrop={handleDrop}
          onRemove={handleRemove}
          onRemoveAll={handleRemoveAll}
          onUpload={handleUpload}
        />
        )}

      </CardContent>
    </Card>
  );
};

UploadOther.propTypes = {
  // @ts-ignore
  device: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired
};

export default UploadOther;
