import React, { Fragment } from "react";
import { Box, CardActions, CardHeader, Grid, Checkbox, Select, MenuItem, TextField, FormHelperText } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Formik } from "formik";
import shortid from "shortid";

const OperatingSystemOptions = ["Windows", "Mac OS", "Chrome OS", "Linux", "No OS"];
const FormFactorOptions = ["Mini Tower", "All In One", "Micro Tower", "Mini Desktop", "Mini PC", "Mini Tower", "Small Form Factor (SFF)", "Tower", "Workstation"];

const MiscDesktop = ({ device, handleChange, edit, handleTextChange, passport }) => {
  return (
    <Formik initialValues={{}} onSubmit={async (): Promise<void> => {}}>
      {(): JSX.Element => (
        <form>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              m: -1,
              p: 2,
            }}
          >
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <CardHeader title="Device Info" />
              </Grid>
            </Grid>
            <Grid style={{ marginLeft: "5px" }} container spacing={1}>
              <Grid item xs={3}>
                <TextField name="grade" value={device["grade"] ?? ""} disabled={!edit} onChange={handleTextChange} label="Grade" size="small" />
              </Grid>

              <Grid key={shortid.generate()} item xs={3}>
                <Select
                  onChange={handleTextChange}
                  name="BasicComponent.operatingSystem"
                  variant="outlined"
                  size="small"
                  value={device["BasicComponent.operatingSystem"]}
                  disabled={!edit}
                  fullWidth
                >
                  {OperatingSystemOptions.map((item,index) => (
                    <MenuItem key={index} value={item}>{item}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>Operating System</FormHelperText>
              </Grid>

              <Grid key={shortid.generate()} item xs={3}>
                <Select onChange={handleTextChange} name="SystemInfo.formFactor" variant="outlined" size="small" value={device["SystemInfo.formFactor"]} disabled={!edit} fullWidth>
                  {FormFactorOptions.map((item,index) => (
                    <MenuItem key={index} value={item}>{item}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>Form Factor</FormHelperText>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="BasicComponent.opticalDrive"
                  value={device["BasicComponent.opticalDrive"] ?? ""}
                  disabled={!edit}
                  onChange={handleTextChange}
                  label="Optical Drive"
                  size="small"
                />
              </Grid>

              <Grid key={shortid.generate()} item xs={3}>
                <FormControlLabel control={<Checkbox color="primary" checked={device["MiscHardware.hdmi"] === 1} onChange={handleChange} name="MiscHardware.hdmi" />} label="HDMI" />
              </Grid>

              <Grid key={shortid.generate()} item xs={3}>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={device["MiscHardware.webcam"] === 1} onChange={handleChange} name="MiscHardware.webcam" />}
                  label="Webcam"
                />
              </Grid>

              <Grid key={shortid.generate()} item xs={3}>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={device["MiscHardware.touchScreen"] === 1} onChange={handleChange} name="MiscHardware.touchScreen" />}
                  label="Touchscreen"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="MiscHardware.screenSize"
                  value={device["MiscHardware.screenSize"] ?? ""}
                  disabled={!edit}
                  onChange={handleTextChange}
                  label="Screen Size"
                  size="small"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField name="MiscHardware.color" value={device["MiscHardware.color"] ?? ""} disabled={!edit} onChange={handleTextChange} label="Color" size="small" />
              </Grid>

              <Grid key={shortid.generate()} item xs={3}>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={device["MiscHardware.bluetooth"] === 1} onChange={handleChange} name="MiscHardware.bluetooth" />}
                  label="Bluetooth"
                />
              </Grid>

              <Grid key={shortid.generate()} item xs={3}>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={device["MiscHardware.displayPort"] === 1} onChange={handleChange} name="MiscHardware.displayPort" />}
                  label="Display Port"
                />
              </Grid>

              <Grid key={shortid.generate()} item xs={3}>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={device["MiscHardware.ethernet"] === 1} onChange={handleChange} name="MiscHardware.ethernet" />}
                  label="Network Port"
                />
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginTop: "20px" }}>
              {passport ? null : (<TextField
                name="description"
                value={device["description"] ?? ""}
                disabled={!edit}
                onChange={handleTextChange}
                label="Description"
                size="medium"
                minRows="2"
                maxRows="4"
                multiline
                fullWidth
              />) }
            </Grid>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default MiscDesktop;
