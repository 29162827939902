import { Fragment } from "react";
import { TableCell, TableRow, Typography } from "@material-ui/core";

const MonitorDetail = ({ device }) => {
  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            Serial Number
          </Typography>
        </TableCell>
        <TableCell>{device["MonitorInfo.Serial"]}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
          Monitor ID
          </Typography>
        </TableCell>
        <TableCell>{device["MonitorInfo.MonitorID"]}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            Model
          </Typography>
        </TableCell>
        <TableCell>{device["MonitorInfo.Model"]}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            Screen Size
          </Typography>
        </TableCell>
        <TableCell>{device["MonitorInfo.Size"]}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            Horizontal Size (cm)
          </Typography>
        </TableCell>
        <TableCell>{device["MonitorInfo.sizeHorCM"]}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            Vertical Size (cm)
          </Typography>
        </TableCell>
        <TableCell>{device["MonitorInfo.sizeVerCM"]}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            PNP Id
          </Typography>
        </TableCell>
        <TableCell>{device["MonitorInfo.PnpID"]}</TableCell>
      </TableRow>
    </Fragment>
  );
};

export default MonitorDetail;
