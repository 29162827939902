import { Fragment } from "react";
import { TableCell, TableRow, Typography } from "@material-ui/core";

const OtherDetail = ({ device }) => {
  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            Serial Number
          </Typography>
        </TableCell>
        <TableCell>{device["SystemInfo.Serial"]}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            Make
          </Typography>
        </TableCell>
        <TableCell>{device["SystemInfo.Make"]}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Typography color="textPrimary" variant="subtitle2">
            Model
          </Typography>
        </TableCell>
        <TableCell>{device["SystemInfo.Model"]}</TableCell>
      </TableRow>
    </Fragment>
  );
};

export default OtherDetail;
