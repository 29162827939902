import { useCallback, useEffect, useState } from "react";
import type { FC } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import { Box, Button, Container, Grid, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from "@material-ui/core";
import { getCertificate,  getGPSLocation,  gspLookup } from "src/dataservies/deviceDataservice";
import React from "react";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { useSnackbar } from 'notistack';
import axios from "../../lib/axios";
import { sha256 } from "js-sha256";
import firebase from "firebase/app";
import { maxWidth } from "@material-ui/system";
import { downloadFingerPrintCertificate } from "src/utils/shareFunctions";
import Google from "./Google";



interface gpsProps {
  gps?: Array<gpsObj>;
  qrhash?: string;
  publicMode?: boolean;
}


interface gpsObj {
  geolocation : string,
  id : number,techtag : number, time_created : string,hash : string
}

const GpsPage: FC<gpsProps> = (props) => {

  const { techTag } = useParams();

  const { gps, qrhash,publicMode } = props;
 
  const isMountedRef = useIsMountedRef();
  const [gpslocation, setGpslocation] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setGpslocation({latitude : position.coords.latitude, longitude : position.coords.longitude});
 
    },
      function(error) {
        if (error.code == error.PERMISSION_DENIED)
        setError("looks like you denied location service.");
      });
      
  }, []);
  const [imagesData, setImagesData] = useState<any[]>([]);
  const [pwCode,setpwCode] = useState('');
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [loadingStatus2, setLoadingStatus2] = useState<boolean>(false);
  const listFiles = useCallback(async () => {
    try {
      const result = await firebase.storage().ref().child(`devices/${techTag}/deviceFingerPrint/GPS`).listAll();
      const files = [];
      for (const item of result.items){
        let metaData = await item.getMetadata();
        files.push({
          item,
          metaData : metaData
        })
      }

      setImagesData(files);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    listFiles();
  }, [listFiles]);

  const hashGPS = async(location : gpsObj) => {
    let fingerprint = `Time Recorded : ${location.time_created}, Geo Location : ${location.geolocation}, techTag : ${location.techtag}`;
    setLoadingStatus(true);
    var blob = new Blob([fingerprint],
                { type: "text/plain;charset=utf-8" });
                const arrayBuffer = await blob.arrayBuffer();    
                var reader = new FileReader();
                reader.readAsDataURL(blob); 
                reader.onloadend = async() => {
                  var base64data = reader.result;          
                  try{
                    await axios.post('https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/uploadDeviceFingerPrintStampGPSLocation', {
                      techTag: techTag,
                      fileName: `gps_${location.id}_${techTag}_originstamp.txt`,
                      baseString: base64data.toString().split(',')[1],
                      id : location.id,
                      fileType: "application/octet-stream",
                      hash : sha256(arrayBuffer),
                    });
                    setLoadingStatus(false);
                    enqueueSnackbar('Device Finger Print Stamped and Uploaded', {
                      autoHideDuration: 3000,
                      anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                      },
                      variant: 'success'
                    });
                  }
                  catch{
                    enqueueSnackbar('Something went wrong', {
                      autoHideDuration: 3000,
                      anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                      },
                      variant: 'warning'
                    });
                    setLoadingStatus(false);
                  }
        
                }


}


  const validate = useCallback(async () => {
    try {
      setLoading(true);
      await gspLookup(pwCode,qrhash,gpslocation);
      setLoading(false);

      enqueueSnackbar('GPS Location Recorded', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'success'
      });
      
    } catch (err) {

      enqueueSnackbar('Something went wrong, please check your Authorization Code again.', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'warning'
      });

      setLoading(false);
    }
  }, [pwCode,qrhash,gpslocation,isMountedRef]);

  return (
    
    <Container maxWidth="lg">
    <Typography
      align="center"
      color="textPrimary"
      sx={{ pt : 5 , pb : 5 }}
      variant="h5"
    >
      Please Enter Authorization Code Located on QR Code
    </Typography>
    <TextField
                        fullWidth
                        label="Enter Authorization Code here"
                        required
                        variant="outlined"
                        value={pwCode}
                        onChange={e => setpwCode(e.target.value)}
                      />

<LoadingButton    
disabled={!pwCode}
                    color="primary"
                    size="large"
                    fullWidth
                    sx={{ mb: 5 , mt:5 }}
                    pending={loading}
                    onClick={validate}
                    variant='contained'
                  >
                    Validate
                  </LoadingButton>

            <Google gps={gps} />

                  <TableContainer component={Paper}>
                  <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Time Recorded</TableCell>
            <TableCell align="left">GeoLocation</TableCell>
            <TableCell>Hash</TableCell>
            <TableCell  align="left">Blockchain Certificates</TableCell>
            {publicMode === false && (<TableCell align="left">Action</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {gps.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
                {row.time_created}
              </TableCell>
              <TableCell align="left" >{row.geolocation}</TableCell>
              <TableCell align="left" >{row.hash}</TableCell>
              <TableCell align="left" >

              <LoadingButton pending={loadingStatus2}   disabled={row.hash === null}  onClick={async ()=>{
                setLoadingStatus2(true);
               await  downloadFingerPrintCertificate(row.hash,0)
                setLoadingStatus2(false)}} sx={{
         }}>
            BITCOIN CERTIFICATE
         </LoadingButton>

         <LoadingButton  pending={loadingStatus2}   disabled={row.hash === null}  onClick={async ()=>{
                setLoadingStatus2(true);
               await  downloadFingerPrintCertificate(row.hash,1)
                setLoadingStatus2(false)}} >
              ETHEREUM CERTIFICATE
          </LoadingButton>
          <LoadingButton pending={loadingStatus2}   disabled={row.hash === null}  onClick={async ()=>{
                setLoadingStatus2(true);
               await  downloadFingerPrintCertificate(row.hash,2)
                setLoadingStatus2(false)}} >
              AION CERTIFICATE
          </LoadingButton>


              </TableCell>

              {publicMode === false && <TableCell align="left" >
              <LoadingButton pending={loadingStatus} ><Button color="primary" disabled={row.hash != null} variant="contained" onClick={() => hashGPS(row)}>
                  Hash GeoLocation
                </Button></LoadingButton>
              </TableCell>}
            </TableRow>
          ))}
        </TableBody>
        </Table>
</TableContainer>

   
    </Container>
  );
};

export default GpsPage;

