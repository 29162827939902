/* eslint-disable */
import { useState, useEffect, useCallback, forwardRef } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  Slide,
  TextField
} from '@material-ui/core';
import { DeviceV2, Location } from 'src/Models/models';
import useSettings from '../../../hooks/useSettings';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import ChevronRightIcon from '../../../icons/ChevronRight';
import TrashIcon from '../../../icons/Trash';
import { deleteLocationById, getLocationById, getLocationOnlyById, getUnAssingedDevicesCall } from 'src/dataservies/locationDataservice';
import { DeviceListTable } from '../devices';
import LocationListTable from './LocationTable';
import ScanMultipleDevicesDialog from '../ScanMultipleDevicesDialog';

const LocationDetail: FC = () => {
  const { settings } = useSettings();
  const isMountedRef = useIsMountedRef();
  const [devices, setDevices] = useState<DeviceV2[]>([]);
  const [UnAssingedDevices, setUnAssingedDevices] = useState<DeviceV2[]>([]);
  const [locationDetail, setLocationDetail] = useState<Location>(null);
  const { locationId } = useParams();
  const deleteLocation = async() => {
      await deleteLocationById(locationId);
      location.reload();
  }
  const [Dialogopen, setDialogopen]  = useState(false);

  const handleClose = (e) => {
    setDialogopen(false);
  }
  const openDialog = () => {
    setDialogopen(true);
  }


  const getUnAssingedDevices = useCallback(async () => {
    try {
      const response = await getUnAssingedDevicesCall();

      if (isMountedRef.current) {
        setUnAssingedDevices(response);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);
  let navigate = useNavigate();
  const getDevices = useCallback(async () => {
    try {
      const response = await getLocationById(locationId);
      const locationOnlyResponse = await getLocationOnlyById(locationId);
      if (isMountedRef.current) {
        setDevices(response);
        if(!locationOnlyResponse){
          navigate('/app/404');
        }else{
          setLocationDetail(locationOnlyResponse)
        }

      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getUnAssingedDevices();
  }, [getUnAssingedDevices]);
  
  useEffect(() => {

  }, [locationDetail]);

  
  useEffect(() => {
    getDevices();
  }, [getDevices]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Location Details | Tech Spec Tool Kit</title>
      </Helmet>
      <Dialog fullScreen onClose={handleClose} open={Dialogopen}>
      <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Devices to location {locationId}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              x
            </Button>
          </Toolbar>
        </AppBar>

      <DialogContent>
         <DeviceListTable action="addToLocation" devices={UnAssingedDevices} locationId={locationId}/>
      </DialogContent>
     
    </Dialog>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Location-
                {locationId}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/app"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/app/locations"
                  variant="subtitle2"
                >
                  Locations
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Location(
                  {locationId}
                  )
                </Typography>
              </Breadcrumbs>
              <Box  sx={{
                  mb: -1,
                  mx: -1,
                  mt: 2
                }}>
                
              <TextField
              style={{width : '250px'}}
              label="Lot Description"
              multiline
              disabled
              rows={2}
              value={locationDetail? locationDetail.description : ''}
        />
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
              <ScanMultipleDevicesDialog UnAssingedDevices={UnAssingedDevices} locationDevices={devices}  importType={"location"} locationId={locationId}/>
                <Button
                  color="primary"
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={openDialog}
                >
                  Add Devices to this location
                </Button>
                <Button
                  startIcon={<TrashIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={deleteLocation}
                >
                  Delete Location
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
          <LocationListTable devices={devices} locationId={locationId}/>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LocationDetail;
