import React, { FC } from 'react'
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Card, CardContent, Paper } from '@material-ui/core';

const containerStyle = {
  height: '400px'
};


interface gMapProp {
  gps?: Array<gpsObj>;
}


interface gpsObj {
  geolocation : string,
  id : number,techtag : number, time_created : string,hash : string
}


const gMap: FC<gMapProp> = (props) =>  {
  const [gpsArr, setGPSArr] = React.useState([]);

  const { gps } = props;
  
  React.useEffect(() => {
    let t = gps.map(g => {
      const temp = g.geolocation.split(",");
      return {
        lat: Number(temp[0]),
        lng:  Number(temp[1]),
      }
    })
    
    setGPSArr(t);
  }, [setGPSArr]);


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAGoKSz7ZLg8J4kyMTQ2nSrDVSaBbH-06M"
  })
 
  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({lat : 41.0895219, lng : -106.2517381});
    map.fitBounds(bounds);
    setMap(map)
  }, [gpsArr,map])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [map])

  return isLoaded ? (
    <>
     <CardContent sx={{marginBottom : "25px"}}>
      <GoogleMap
        options={
         {
          zoom : 4,
          
         }
        }
        mapContainerStyle={containerStyle}
        center={{lat : 41.0895219, lng : -106.2517381}}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {gpsArr.map((g,i) => ( <><Marker
          position={g}         
        />
        <InfoWindow position={g}>
          <p>
          {i === 0 ? "Origin" : `Stop ${i}`}
          </p>
        </InfoWindow>
        </>
        ))}
      
        <></>
      </GoogleMap>
      </CardContent>
    </>
    
  ) : <></>
}

export default gMap