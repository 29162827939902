import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Box } from "@material-ui/core";
import { FC } from "react";
import { useState } from 'react';
import { releaseDevicesFromLocation} from "src/dataservies/locationDataservice";

interface ReleaseDevicesToLocationDialogProps {
    validationSource : number[];
    locationId? : number;
    open : boolean;
    handleClose : (e:any) => void;
}

const ReleaseDevicesToLocationDialog: FC<ReleaseDevicesToLocationDialogProps> = (props) => {
    const { open, handleClose, locationId,validationSource = []} = props;
const closeDialog = (e) => {
    handleClose(e)
    setlotError([])
}
const submitBulkAction  = async () => {
    let numTTArr = techTags.map(Number);
    let duplicateTT = numTTArr.filter(x => !validationSource.includes(x)) ;
    if(duplicateTT.length > 0){
      setlotError(duplicateTT);
      return;
    }
    else{
        await releaseDevicesFromLocation(numTTArr);
  
     window.location.reload();
    }
}


const [techTags, setTechTags] = useState<number[] | null>([]);
const [lotError, setlotError] = useState<string[] | null | number[]>([]);
const onTechTagChange = (e) => {
    setTechTags(e.target.value.split('\n'));
}

return (

     <Dialog
     fullWidth={true}
        maxWidth={"sm"}
        open={open}
      >
        <DialogTitle>
          {`Release Devices from Location: ${locationId}`}
          
        </DialogTitle>
        <Box sx={{ml : 3}}>
        <Typography>Please enter only one TechTag perline, with no comma (,) needed</Typography>
        </Box>
        <DialogContent>
          <TextField
          fullWidth
          id="outlined-multiline-static"
          label="Enter or Scan TechTags here"
          multiline
          onChange={onTechTagChange}
          rows={10}
        />
        <Box sx={{mt : 2}}>
        {lotError.map(o => (<Typography color='red'>Error: <b>{o}</b> does not belong to this Location </Typography>))}
        <b>Please remove above TechTags from the list and try again.</b>
        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
          <Button disabled={techTags.length === 0} onClick={submitBulkAction}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    
)

}


export default ReleaseDevicesToLocationDialog;
