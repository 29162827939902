import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Modal, Select, Typography } from "@material-ui/core";
import { FC } from "react";
import AddIcon from '@mui/icons-material/Add';
import * as React from 'react';
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { getAllStatus, updateStatusByTechTags, updateStatusByTechTagsForLocation } from "src/dataservies/deviceDataservice";

type updateStatusProps = {
    selectedDevices : String[];
    mode? : String;
  }
  
const  UpdateStatusButton :FC<updateStatusProps>  = (props) => {

  const [selectedStatus, setSelectedStatus] = React.useState('');
  const [Status,setStatus] = React.useState<{status : string}[]>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isMountedRef = useIsMountedRef();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedStatus(event.target.value.toString());
  };
  const getLocations = React.useCallback(async () => {
    try {
        const result = await getAllStatus();
        if (isMountedRef.current) {
          setStatus(result);
        }
    
    } catch (err) {
     
      console.error(err);
    }
  }, [isMountedRef]);

  
  React.useEffect(() => {
    getLocations();
  }, [getLocations]);

    const updateStatus = async () => {
      if(props.mode === "location"){
        await updateStatusByTechTagsForLocation(props.selectedDevices,selectedStatus);
      }else{
        await updateStatusByTechTags(props.selectedDevices,selectedStatus);
      }
       
        location.reload();
      }

    return (<>
    
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Select Status"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Select
          fullWidth
          value={selectedStatus}
          onChange={handleChange}
        >
         {Status.map((s,i) => (
           <MenuItem key={i} value={s.status}>{s.status}</MenuItem>
         ))} 
          </Select>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={updateStatus} color="primary" autoFocus>
            Select
          </Button>
        </DialogActions>
      </Dialog>
    
    <Button size='small' startIcon={<AddIcon />} disabled={props.selectedDevices.length === 0} onClick={handleOpen} autoFocus>
    Update Status for {props.selectedDevices.length} {props.mode === "location" ? "locations" : "devices"}
  </Button></>)
  }

  export default UpdateStatusButton;