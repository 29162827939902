import React, { useState } from 'react';
import type { FC } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Autocomplete
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFirestore } from 'reactfire';
import { Device, DeviceType, RefurbisherCompany } from 'src/Models/models';
import { emptyModel } from 'src/utils/shareFunctions';
import FileDropzone from '../../FileDropzone';
import QuillEditor from '../../QuillEditor';

const deviceType = [
  {
    value: 'laptop',
    label: 'Laptop'
  },
  {
    label: 'Desktop',
    value: 'desktop'
  },
  {
    label: 'Tablet',
    value: 'tablet'
  }
];

const ProductCreateForm: FC = (props) => {
  const usersCollection = useFirestore().collection('RefurbisherCompany');
  const devicesCollection = useFirestore().collection('Devices');
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState<any[]>([]);
  const [created, setCreated] = useState<boolean>(false);
  const [refurbisherList, setRefurbisherList] = useState<RefurbisherCompany[]>([]);
  const [selectedRefurbisher, setSelectedRefurbisher] = useState<string>(null);
  const [selectedDeviceType, setSelectedDeviceType] = useState<string>(null);
  const handleDrop = (newFiles: any): void => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };
  const [open, setOpen] = React.useState(false);
  const [openDevice, setOpenDevice] = React.useState(false);
  const loading = open && refurbisherList.length === 0;
  const handleRemove = (file): void => {
    setFiles((prevFiles) => prevFiles.filter((_file) => _file.path
      !== file.path));
  };

  const handleRemoveAll = (): void => {
    setFiles([]);
  };
  const handleSelected = (v) => {
    setSelectedRefurbisher(refurbisherList.find((r) => r.company === v.target.textContent).id);
  };
  const handleDeviceTypeSelected = (v) => {
    setSelectedDeviceType(deviceType.find((r) => r.label === v.target.textContent).value);
  };

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        const result = usersCollection.get();
        // setRefurbisherList((await result).docs.map((r) => r.data() ));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);
  return (
    <Formik
      initialValues={{
        refurbisherCompany: '',
        deviceType: '',
        category: '',
        description: '',
        images: [],
        includesTaxes: false,
        isTaxable: false,
        name: '',
        price: '',
        productCode: '',
        productSku: '',
        salePrice: '',
        submit: null
      }}
      validationSchema={
        Yup
          .object()
          .shape({
            refurbisherCompany: Yup.string().max(255),
            deviceType: Yup.string().max(255),
          })
      }
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }): Promise<void> => {
        try {
          values.refurbisherCompany = selectedRefurbisher;
          values.deviceType = selectedDeviceType;
       
          const currentDevice : Device = emptyModel;
          currentDevice.deviceType = DeviceType[values.deviceType];
          // currentDevice.refurbisherId = values.refurbisherCompany;
          await devicesCollection.doc().set(currentDevice);
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar('Device Created', {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top'
            },
            variant: 'success'
          });
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values
      }): JSX.Element => (
        <form
          onSubmit={handleSubmit}
          {...props}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              {!created && (
              <Box>
                <Card>
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <Autocomplete
                          onInputChange={handleSelected}
                          id="asynchronous-demo"
                          open={open}
                          onOpen={() => {
                            setOpen(true);
                          }}
                          onClose={() => {
                            setOpen(false);
                          }}
                          getOptionLabel={(option) => option.company}
                          options={refurbisherList}
                          loading={loading}
                          renderInput={(params) => (
                            <TextField
                              value={values.refurbisherCompany}
                              fullWidth
                              {...params}
                              label="Refurbisher Company"
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <Autocomplete
                          onInputChange={handleDeviceTypeSelected}
                          id="asynchronous-demo2"
                          open={openDevice}
                          onOpen={() => {
                            setOpenDevice(true);
                          }}
                          onClose={() => {
                            setOpenDevice(false);
                          }}
                          getOptionLabel={(option) => option.label}
                          options={deviceType}
                          renderInput={(params) => (
                            <TextField
                              value={values.deviceType}
                              fullWidth
                              {...params}
                              label="Device Type"
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        {' '}
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                        >
                          Create Product
                        </Button>
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              )}
              {created && (
              <>
                <Card>
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          error={Boolean(touched.name && errors.name)}
                          label="Tech Tag"
                          disabled
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Typography
                      color="textSecondary"
                      sx={{
                        mb: 2,
                        mt: 3
                      }}
                      variant="subtitle2"
                    >
                      Description
                    </Typography>
                    <QuillEditor
                      onChange={(value: string): void => setFieldValue(
                        'description',
                        value
                      )}
                      placeholder="Write something"
                      sx={{ height: 400 }}
                      value={values.description}
                    />
                    {(touched.description && errors.description) && (
                    <Box sx={{ mt: 2 }}>
                      <FormHelperText error>
                        {errors.description}
                      </FormHelperText>
                    </Box>
                    )}
                  </CardContent>
                </Card>
                <Box sx={{ mt: 3 }}>
                  <Card>
                    <CardHeader title="Upload Images" />
                    <CardContent>
                      <FileDropzone
                        accept="image/*"
                        files={files}
                        onDrop={handleDrop}
                        onRemove={handleRemove}
                        onRemoveAll={handleRemoveAll}
                      />
                    </CardContent>
                  </Card>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Card>
                    <CardHeader title="Prices" />
                    <CardContent>
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.price && errors.price)}
                            fullWidth
                            helperText={
                            touched.price && errors.price
                              ? errors.price
                              : 'If you have a sale price this will be shown as old price'
                          }
                            label="Price"
                            name="price"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="number"
                            value={values.price}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.salePrice && errors.salePrice)}
                            fullWidth
                            helperText={touched.salePrice && errors.salePrice}
                            label="Sale price"
                            name="salePrice"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="number"
                            value={values.salePrice}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Box sx={{ mt: 2 }}>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={values.isTaxable}
                              color="primary"
                              name="isTaxable"
                              onChange={handleChange}
                              value={values.isTaxable}
                            />
                        )}
                          label="Product is taxable"
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={values.includesTaxes}
                              color="primary"
                              name="includesTaxes"
                              onChange={handleChange}
                              value={values.includesTaxes}
                            />
                        )}
                          label="Price includes taxes"
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </>
              ) }
            </Grid>
            {created && (
              <>
                <Grid
                  item
                  lg={4}
                  md={6}
                  xs={12}
                >
                  <Card>
                    <CardHeader title="Organize" />
                    <CardContent>
                      <Box sx={{ mt: 2 }}>
                        <TextField
                          error={Boolean(touched.productCode && errors.productCode)}
                          fullWidth
                          helperText={touched.productCode && errors.productCode}
                          label="Product Code"
                          name="productCode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.productCode}
                          variant="outlined"
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <TextField
                          error={Boolean(touched.productSku && errors.productSku)}
                          fullWidth
                          helperText={touched.productSku && errors.productSku}
                          label="Product Sku"
                          name="productSku"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.productSku}
                          variant="outlined"
                        />
                      </Box>
                    </CardContent>
                  </Card>
                  {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>
                      {errors.submit}
                    </FormHelperText>
                  </Box>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      mt: 3
                    }}
                  >
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      Create Product
                    </Button>
                  </Box>
                </Grid>
              </>
            ) }
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ProductCreateForm;
