import { DeviceV2 } from "src/Models/models";
import axios from "../lib/axios";

export const getAllDevices = async (deviceType) => {
  return await (
    await axios.get(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getAllDevices?deviceType=${deviceType}`)
  ).data;
};


export const getAllStatus = async () => {
  return await (
    await axios.get(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getAllStatus`)
  ).data;
};


export const getTechTagByLocations = async (lotNumbers) => {
  return await (
    await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getTechTagByLocations`, {lotNumbers : lotNumbers})
  ).data;
};




export const updateDeviceByTag = async (techTag, device) => {
  return await (
    await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/updateDeviceByTag/${techTag}`, device)
  ).data;
};

export const getDeviceByTechtag = async (techTag) => {
  return await (
    await axios.get(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getDeviceByTechtag/${techTag}`)
  ).data;
};


export const getWipeReportByTechtags = async (techTags) => {
  return await (
    await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getWipeReportByTechtags`,{
      techTag : techTags
    })
  ).data;
};

export const getWipeReportByTechtag = async (techTag) => {
  return await (
    await axios.get(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getWipeReportByTechtag/${techTag}`)
  ).data;
};

export const getGPSLocation = async (techTag) => {
  return await (
    await axios.get(`https://us-central1-techreusetoolkit.cloudfunctions.net/publicApi/public/getGPS/${techTag}`)
  ).data;
};


export const getWipeReportByTechtagUnAuth = async (techTag) => {
  return await (
    await axios.get(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/public/getWipeReportByTechtagUnAuth/${techTag}`)
  ).data;
}

export const getEventByTechTag = async (techTag) => {
  return await (
    await axios.get(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/getEventByTechTag/${techTag}`)
  ).data;
};

export const getDeviceByTechtagPublic = async (techTag) => {
  return await (
    await axios.get(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/public/getDeviceByTechtag/${techTag}`)
  ).data;
};

export const getDeviceEventsByTechtagPublic  = async (techTag) => {
  return await (
    await axios.get(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/public/getDeviceEventsByTechtagPublic/${techTag}`)
  ).data;
};

export const deviceSearchByParts = async (part) => {
  return await (
    await axios.get(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/deviceSearchByParts/${part}`)
  ).data;
};

export const advanceSearch = async (CpuQuery, MakeQuery, ModelQuery) => {
  return await (
    await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/deviceAdvanceSearch`, {
      cpu: CpuQuery,
      make: MakeQuery,
      model: ModelQuery,
    })
  ).data;
};

export const recycleDeviceByTechTag = async (techTags : Array<Number>) => {
  return await (
    await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/recycleDeviceByTechTag`, {
      techTags: techTags,
    })
  ).data;
};

export const unrecycleDeviceByTechTag = async (techTags : Array<Number>) => {
  return await (
    await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/unrecycleDeviceByTechTag`, {
      techTags: techTags,
    })
  ).data;
};

export const importDataToCreateTechTags = async (devices) => {
  return await (
    await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/importDataToCreateTechTags`, {
      Devices : devices
    })
  ).data;
}
export const updateStatusByTechTags = async (techTags, status) => {
  return await (
    await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/updateStatusByTechTags`, {
      techTags: techTags,
      status: status,
    })
  ).data;
};


export const updateStatusByTechTagsForLocation =  async (techTags, status) => {
  return await (
    await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/updateStatusByTechTagsForLocation`, {
      locations: techTags,
      status: status,
    })
  ).data;
};

export const emailtts = async (tts,to,pdf) => {
  return await (
    await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/sendTTemails`, {
      tts: tts,
      to: to,
      pdfbase64 : pdf
    })
  ).data;
}

export const uploadDeviceJSON = async (device : DeviceV2) => {
  return await (
    await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/mysql/uploadDeviceJSON`, {
      device: device
    })
  ).data;
};

export const gspLookup = async (pw: string,hash : string,gpslocation : any) => {
  return await (
    await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/publicApi/public/qrlookup`, {
      pw: pw,
      hash : hash,
      gpslocation: `${gpslocation.latitude},${gpslocation.longitude}`

    })
  ).data;
}

export const getCertificate = async (hash: string, coin: string) => {
  return await (
    await axios.post(`https://us-central1-techreusetoolkit.cloudfunctions.net/publicApi/getProof`, {
      hash: hash,
      coinType:  coin
    })
  ).data;
};
