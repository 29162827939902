import { useState, useEffect, useCallback } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { Location } from 'src/Models/models';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import ChevronRightIcon from '../../icons/ChevronRight';
import PlusIcon from '../../icons/Plus';
import useSettings from '../../hooks/useSettings';
import gtm from '../../lib/gtm';
import { getAllLocations, getLocationById } from 'src/dataservies/locationDataservice';
import SkletionLoader from 'src/components/dashboard/SkletionLoader';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, DataGrid } from '@mui/x-data-grid';
import moment from 'moment-timezone';
import ExportOtherPlatFormButton from 'src/components/dashboard/devices/dataGrodToolBar/ExportOtherPlatFormButton';
import UpdateStatusButton from 'src/components/dashboard/devices/dataGrodToolBar/UpdateStatusButton';

const LocationList: FC = () => {
  const isMountedRef = useIsMountedRef();
  const { settings } = useSettings();
  const [Locations,setLocations] = useState<Location[]>([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [completeApiCall, setCompleteApiCall] = useState<boolean>(false);
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);
  
  const LocationColumn = [
    {
      field : "id",
      headerName: 'Location ID',
      renderCell: (row) =>  { return(  <Link
        color="primary"
        component={RouterLink}
        to={`/app/locations/${row.id}`}
      >
        {row.id}
      </Link>)},
  },

  { field: "name", width: 250, headerName: 'Name', renderCell: (data) => null },
  { field: "description", width: 250, headerName: 'Description', renderCell: (data) => null },
  { field: "Device.totalDevices", width: 250, headerName: 'Total Devices', renderCell: (data) => null },
  { field: "createTimeinUTC", width: 250, headerName: 'Created on (West Coast time)', renderCell: (data) =>  { return(   <Typography variant='inherit'>{moment(data.row.createTimeinUTC).tz('America/Los_Angeles').format('YYYY-MM-DD hh:mm:ss A')}</Typography>)}, },
  ]
  
  const getLocations = useCallback(async () => {
    try {
        const result = await getAllLocations();
        if (isMountedRef.current) {
          setLocations(result);
        }
        setCompleteApiCall(true);
    } catch (err) {
      setCompleteApiCall(true);
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <UpdateStatusButton mode={"location"} selectedDevices={selectedItems} />
       <ExportOtherPlatFormButton mode={"location"} selectedDevices={selectedItems} />
      </GridToolbarContainer>
    );
  };

  
  return (
    <>
      <Helmet>
        <title>Dashboard: Location List</title>
      </Helmet>
      
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Location List
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/app"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
               
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Locations
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                  to="/app/locations/new"
                >
                  Add Location
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ backgroundColor: 'white',mt: 3 }}>
        
          {completeApiCall ? ( <div style={{ height: 500 }}>
<DataGrid onSelectionModelChange={item => setSelectedItems(item)} checkboxSelection columns={LocationColumn} rows={Locations} getRowId={(row) => row.id} components={{ Toolbar: CustomToolbar }} />
</div>) : (<SkletionLoader />)}
           
          </Box>
        </Container>
      </Box>
  
    </>
  );
};

export default LocationList;
