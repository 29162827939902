import React from "react";
import { Suspense, lazy } from "react";
import { Link } from "react-router-dom";
import AdminGuard from "./components/AdminGuard";
import AuthGuard from "./components/AuthGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import LocationDetail from "./components/dashboard/location/LocationDetail";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import LocationList from "./pages/dashboard/LocationList";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const PasswordRecovery = Loadable(lazy(() => import("./pages/authentication/PasswordRecovery")));
const PasswordReset = Loadable(lazy(() => import("./pages/authentication/PasswordReset")));
const Register = Loadable(lazy(() => import("./pages/authentication/Register")));
const Invitation = Loadable(lazy(() => import("./pages/authentication/Invitation")));
const VerifyCode = Loadable(lazy(() => import("./pages/authentication/VerifyCode")));

// Dashboard pages

const Account = Loadable(lazy(() => import("./pages/dashboard/Account")));
const CustomerDetails = Loadable(lazy(() => import("./pages/dashboard/CustomerDetails")));
const CustomerEdit = Loadable(lazy(() => import("./pages/dashboard/CustomerEdit")));
const CustomerList = Loadable(lazy(() => import("./pages/dashboard/CustomerList")));
const CustomerCreate = Loadable(lazy(() => import("./pages/dashboard/CustomerCreate")));
const LotsDetails = Loadable(lazy(() => import("./pages/dashboard/LotDetails")));
const LotList = Loadable(lazy(() => import("./pages/dashboard/LotList")));
const Overview = Loadable(lazy(() => import("./pages/dashboard/Overview")));
const LocationCreate = Loadable(lazy(() => import("./pages/dashboard/LocationCreate")));
const DeviceList = Loadable(lazy(() => import("./pages/dashboard/DeviceList")));
const DevicesDetail = Loadable(lazy(() => import("./pages/dashboard/DevicesDetail")));
const DeviceEdit = Loadable(lazy(() => import("./pages/dashboard/DevicesEdit")));
const Download = Loadable(lazy(() => import("./pages/dashboard/Download")));
const DevicePassport = Loadable(lazy(() => import("./pages/dashboard/DevicePassport")));
const Tags = Loadable(lazy(() => import("./pages/tags/tags")));
const CreateTags = Loadable(lazy(() => import("./pages/tags/createTags")));
// Error pages

const AuthorizationRequired = Loadable(lazy(() => import("./pages/AuthorizationRequired")));
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));
const HelpPage =  Loadable(lazy(() => import("./pages/helpPage")));
// Other pages

const Home = Loadable(lazy(() => import("./pages/Home")));

//admin only pages
const AdminUserList = Loadable(lazy(() => import("./pages/admin/RefurbishersList")));


const routes = [
  {
    path: "auth",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login-unguarded",
        element: <Login />,
      },
      {
        path: "password-recovery",
        element: <PasswordRecovery />,
      },
      {
        path: "password-reset",
        element: <PasswordReset />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "register-unguarded",
        element: <Register />,
      },
      {
        path: "verify-code",
        element: <VerifyCode />,
      },
      {
        path: "invitation/:id/:inviter",
        element: <Invitation />,
      },
    ],
  },
  {
    path: "public",
    children: [
      {
        path: "/:techTag",
        element: <DevicePassport />,
      },
    ],
  },
  {
    path: "app",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Overview />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "help",
        element : <HelpPage />

      },
      {
        path: "download",
        element: <Download />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "tags",
        children: [
          {
            path: "/",
            element: <Tags />,
          },
          {
            path: "create",
            element: <CreateTags />,
          },
        ],
      },
      {
        path: "recycleBin",
        children: [
          {
            path: "/",
            element: <DeviceList deviceType={"Recycled"} />,
          },
        ],
      },
      {
        path: "unassigned",
        children: [
          {
            path: "/",
            element: <DeviceList deviceType={"Unassigned"} />,
          },
          {
            path: ":techTag",
            element: <DevicesDetail />,
          },
          {
            path: ":techTag/edit",
            element: <DeviceEdit />,
          },
        ],
      },
      {
        path: "monitors",
        children: [
          {
            path: "/",
            element: <DeviceList deviceType={"monitor"} />,
          },
          {
            path: ":techTag",
            element: <DevicesDetail />,
          },
          {
            path: ":techTag/edit",
            element: <DeviceEdit />,
          },
        ],
      },
      {
        path: "computers",
        children: [
          {
            path: "/",
            element: <DeviceList deviceType={"Computer"} />,
          },
          {
            path: ":techTag",
            element: <DevicesDetail />,
          },
          {
            path: ":techTag/edit",
            element: <DeviceEdit />,
          },
        ],
      },
      {
        path: "admin",
        children: [
          {
            path: "/",
            element: <AdminGuard><AdminUserList /></AdminGuard>,
          },
        ],
      },
      {
        path: "desktop",
        children: [
          {
            path: "/",
            element: <DeviceList deviceType={"Desktop"} />,
          },
          {
            path: ":techTag",
            element: <DevicesDetail />,
          },
          {
            path: ":techTag/edit",
            element: <DeviceEdit />,
          },
        ],
      },
      {
        path: "laptop",
        children: [
          {
            path: "/",
            element: <DeviceList deviceType={"Laptop"} />,
          },
          {
            path: ":techTag",
            element: <DevicesDetail />,
          },
          {
            path: ":techTag/edit",
            element: <DeviceEdit />,
          },
        ],
      },
      {
        path: "mobile",
        children: [
          {
            path: "/",
            element: <DeviceList deviceType={"Mobile"} />,
          },
          {
            path: ":techTag",
            element: <DevicesDetail />,
          },
          {
            path: ":techTag/edit",
            element: <DeviceEdit />,
          },
        ],
      },
      {
        path: "other",
        children: [
          {
            path: "/",
            element: <DeviceList deviceType={"Other"} />,
          },
          {
            path: ":techTag",
            element: <DevicesDetail />,
          },
          {
            path: ":techTag/edit",
            element: <DeviceEdit />,
          },
        ],
      },
      {
        path: "locations",
        children: [
          {
            path: "/",
            element: <LocationList />,
          },
          {
            path: "new",
            element: <LocationCreate />,
          },
          {
            path: ":locationId",
            element: <LocationDetail />,
          },
          {
            path: ":locationId/edit",
            element: <DeviceEdit />,
          },
        ],
      },
      {
        path: "customer",
        children: [
          {
            path: "/",
            element: <CustomerList />,
          },
          {
            path: "create",
            element: <CustomerCreate />,
          },
          {
            path: ":customerID",
            element: <CustomerDetails />,
          },
          {
            path: ":customerID/edit",
            element: <CustomerEdit />,
          },
        ],
      },
      {
        path: "lots",
        children: [
          {
            path: "/",
            element: <LotList />,
          },
          {
            path: ":lotId",
            element: <LotsDetails />,
          },
          {
            path: "new",
            element: <LocationCreate lot={true} />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
